<div class="bg-login">
  <!--wrapper-->
  <div class="wrapper">
    <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
          <div class="col mx-auto">
            <div class="row my-5" *ngIf="envName != 'prod'">
              <div class="col-12 text-center">
                <div class="alert alert-warning" role="alert">
                  {{envName}}
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="border p-4 rounded">
                  <div class="text-center">
                    <div class="mb-4 text-center bg-exctra py-1">
                      <img src="assets/images/syme-logo.png" width="180" alt=""/>
                    </div>
                    <h3 class="">Login</h3>
                  </div>
                  <div class="login-separater text-center mb-4"><span>{{'translations.WELCOME'|translate}}</span>
                    <hr/>
                  </div>
                  <div class="form-body">
                    <form class="row g-3" [formGroup]="form">
                      <div class="col-12" *ngIf="error">
                        {{'translations.' + error.message|translate}}
                      </div>
                      <div class="col-12">
                        <input type="text" class="form-control" id="inputUsername"
                               formControlName="username" [placeholder]="'translations.LOGIN_FORM_USERNAME'|translate">
                      </div>
                      <div class="col-12">
                        <div class="input-group" id="show_hide_password">
                          <input type="password" class="form-control border-end-0"
                                 id="inputChoosePassword" formControlName="password"
                                 [placeholder]="'translations.LOGIN_FORM_PASSWORD'|translate"> <a href="#"
                                                                  (click)="$event.preventDefault()"
                                                                  class="input-group-text bg-transparent"><i
                          class="bx bx-hide" (click)="togglePasswordVisibility($event)"></i></a>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-grid">
                          <button type="submit" class="btn btn-exctra" (click)="onLogin()"><i
                            class="bx bxs-lock-open"></i>Login
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <a href="JavaScript:;" (click)="onForgotpassword()">
                            <span class="material-icons inline">ads_click</span>
                            {{'translations.LOGIN_FORM_FORGOT_PASSWORD'|translate}}
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </div>
  </div>
  <!--end wrapper-->
</div>
