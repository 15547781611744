import { createReducer, on } from "@ngrx/store";
import * as AttributeActions from "./attribute.actions";
import { Attribute } from "../../models/attribute";

export const attributesFeatureKey = 'Attributes';

export interface AttributesAppState {
    [attributesFeatureKey]: AttributeState;
}

export interface AttributeState {
    attributes: Attribute[];
    selectedAttribute: Attribute|null;
    isLoading: boolean;
}

export const initialAttributeState: AttributeState =  {
    attributes: [],
    selectedAttribute: null,
    isLoading: false
}


export const AttributeReducer = createReducer(
    initialAttributeState,
    on(AttributeActions.loadAttributes, (state): AttributeState => {
        const attributes = [] as Attribute[];
        const isLoading = true;
        return {...state, attributes, isLoading};
    }),
    on(AttributeActions.loadAttributesSuccess, (state, action): AttributeState => {
        const attributes = action.attributes;
        const isLoading = false;
        return {...state, attributes, isLoading};
    }),
    on(AttributeActions.updateAttributeSuccess, (state: AttributeState, { updateAttribute }): AttributeState => {
        let attributes = state.attributes.filter((_) => _.id != updateAttribute.id);
        attributes.unshift(updateAttribute);
        const isLoading = false;
        return {...state, attributes, isLoading};
    }),
    on(AttributeActions.addAttributeSuccess, (state: AttributeState, { addAttribute }): AttributeState => {
        let attributes = [...state.attributes];
        attributes.unshift(addAttribute);
        const isLoading = false;
        return {...state, attributes, isLoading};
    }),
    on(AttributeActions.deleteAttributeSuccess, (state: AttributeState, { deleteAttributeId }): AttributeState => {
        let attributes = state.attributes.filter((_) => _.id != deleteAttributeId);
        const isLoading = false;
        return {...state, attributes, isLoading};
    })
);
