import { environment } from '../environments/environment';
import * as _ from 'lodash';

export const config = {
    auth: {
        idleTimeout: 600
    },
    restServers: {
        basic: {
            url: environment.apiHost
        }
    },
    languages: ['de', 'fr', 'it'],
    password: {
        minLength: 10
    },
    route: {
        default: 'dashboard/start',
        titleSuffix: ' - Syme eXctra 3.0'
    },
    roles: [
        'ROLE_SUPER_ADMIN',
        'ROLE_CUSTOMER_ADMIN',
        'ROLE_BASIC_USER',
        'ROLE_MENTOR_TOURING_TESTER',
        'ROLE_MULTIPLE_MENTOR',
        'ROLE_FILIALE_MENTOR',
        'ROLE_MASTER_GUIDE',
        'ROLE_MANAGER'
    ],
    features: [
        'YEARLY_REPORT',
        'YEARLY_REPORT_SPECIALISTS',
        'MONTHLY_REPORT',
        'OVERVIEW_ACHIEVEMENTS',
        'OVERVIEW_EVALUATIONS',
        'OVERVIEW_MENTORS',
        'PROMISING_EMPLOYEES',
        'RANKING',
        'PROJECT_LOCATION_SELECTABLE',
        'TRESHOLD_EDITABLE',
        'IP_SECURITY'
    ],
    dataTables: {
        timeout: 3000,
        headerLabels: () => {
            const categories =  _.map(_.range(1,4), i => `categories${i}`);
            const attributes =  _.map(_.range(1, 6), i => `attributes${i}`);
            return _.keyBy(_.concat(categories, attributes), i => i);
        }
    },
    reports: {
        availableStatisticYears: 2
    },
    chartColors: [{ // dark green
        fillColor: "rgba(0,121,124,0.8)",
        strokeColor: "rgba(0,121,124,1.0)",
        pointColor: "rgba(0,121,124,1.0)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(0,121,124,1.0)"
    }, { // green
        fillColor: "rgba(84,188,151,0.4)",
        strokeColor: "rgba(0,121,124,1.0)",
        pointColor: "rgba(84,188,151,0.4)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(84,188,151,0.4)"
    }, { // dark yellow
        fillColor: "rgba(253,180,93,0.4)",
        strokeColor: "rgba(253,180,93,0.4)",
        pointColor: "rgba(253,180,93,0.4)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(253,180,93,0.4)"
    }, { // gray
        fillColor: "rgba(121,121,121,0.8)",
        strokeColor: "rgba(121,121,121,0.8)",
        pointColor: "rgba(121,121,121,0.8)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(121,121,121,0.8)",
    }, { // blue
        fillColor: "rgba(38,198,218,0.8)",
        strokeColor: "rgba(38,198,218,0.8)",
        pointColor: "rgba(38,198,218,0.8)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(38,198,218,0.8)",
    }],
    chartAttributeColors: [{ // purple
        fillColor: "rgba(78,5,122,0.8)",
        strokeColor: "rgba(78,5,122,1.0)"
    }, { // green
        fillColor: "rgba(38,198,218,0.4)",
        strokeColor: "rgba(38,198,218,1.0)"
    }, { // yellow
        fillColor: "rgba(225,218,44,0.94)",
        strokeColor: "rgba(225,218,44,0.4)"
    }, { // green
        fillColor: "rgba(18,173,110,0.8)",
        strokeColor: "rgba(18,173,110,0.8)"
    }, { // blue
        fillColor: "rgba(206,9,57,0.8)",
        strokeColor: "rgba(206,9,57,0.8)"
    }]
};
