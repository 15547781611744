<div class="bg-login">
    <!--wrapper-->
    <div class="wrapper">
        <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div class="container-fluid">
                <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div class="col mx-auto">

                        <div class="card">
                            <div class="card-body">
                                <div class="border p-4 rounded">
                                    <div class="text-center">
                                        <div class="mb-4 text-center bg-exctra py-1">
                                            <img src="assets/images/syme-logo.png" width="180" alt=""/>
                                        </div>
                                        <h3 class="">{{ 'translations.ENTER_NEW_PASSWORD'|translate }}</h3>
                                    </div>
                                    <hr/>
                                    <div class="form-body">
                                        <form class="row g-3" [formGroup]="form" novalidate>
                                            <div class="col-12">
                                                {{'translations.PASSWORD_EXPIRED'|translate}}
                                                <br />
                                                {{'translations.INSECURE_PASSWORD_HINT2'|translate}}<br /><br />

                                                <ul>
                                                    <li>{{'translations.PASSWORD_CRITERIA1'|translate}}</li>
                                                    <li>{{'translations.PASSWORD_CRITERIA2'|translate}}</li>
                                                    <li>{{'translations.PASSWORD_CRITERIA3'|translate}}</li>
                                                    <li>{{'translations.PASSWORD_CRITERIA4'|translate}}</li>
                                                    <li>{{'translations.PASSWORD_CRITERIA5'|translate}}</li>
                                                    <li>{{'translations.PASSWORD_CRITERIA6'|translate}}</li>
                                                </ul>

                                            </div>
                                            <input type="hidden" formControlName="username" />
                                            <div class="p-4 rounded  border">
                                                <h4 class="mt-5 font-weight-bold">{{'translations.RESET_PASSWORD_FORM_HEADLINE'|translate}}</h4>
                                                <p class="text-muted">{{'translations.RESET_PASSWORD_FORM_HINT'|translate}}</p>
                                                <div class="col-12 ng-invalid" *ngIf="responseMessage">
                                                    <span class="field-message">{{'translations.' + responseMessage|translate}}</span>
                                                </div>
                                                <div class="my-4">
                                                    <label class="form-label">{{'translations.RESET_PASSWORD_FORM_PASSWORD'|translate}}</label>
                                                    <input type="password" class="form-control form-control-lg" formControlName="password" required />
                                                    <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['required']">
                                                        {{'translations.PLS_INSERT_PASSWORD'|translate}}
                                                    </div>
                                                    <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['minlength']">
                                                        {{'translations.PASSWORD_MINLENGTH_ERROR'|translate: {passwordMinLength: passwordMinLength} }}
                                                    </div>
                                                    <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['pattern']">
                                                        {{'translations.PASSWORD_PATTERN_ERROR'|translate}}
                                                    </div>
                                                </div>
                                                <div class="my-4 col-12">
                                                    <label class="form-label">{{'translations.RESET_PASSWORD_FORM_PASSWORD_REPEAT'|translate}}</label>
                                                    <input type="password" class="form-control form-control-lg" formControlName="password_repeat" />
                                                    <div class="field-message" *ngIf="(formControl['password_repeat'].touched || submitted) && formControl['password_repeat'].errors?.['required']">
                                                        {{'translations.PLS_REPEAT_PASSWORD'|translate}}
                                                    </div>

                                                    <div class="field-message"  *ngIf="(formControl['password_repeat'].touched || submitted) && form.errors?.['notTheSame']">
                                                        {{'translations.PW_AND_REPEAT_NOT_MATCHING_ERROR'|translate}}
                                                    </div>
                                                </div>
                                                <div class="d-grid gap-2">
                                                    <button type="submit" class="btn btn-exctra" (click)="onSend()">{{'translations.ACTION_SEND'|translate}}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
    <!--end wrapper-->
</div>
