import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromLocation from "./location.reducer";
import { LocationState } from "./location.reducer";

export const selectLocationState = createFeatureSelector<fromLocation.LocationState>(
    fromLocation.locationsFeatureKey
);

export const selectAllLocations = createSelector(
    selectLocationState,
    ls => ls.locations
)

export const selectLocations = createFeatureSelector<LocationState>('Locations');

export const selectLocationById = (locationId: number) => createSelector(
    selectLocations, (locationState: LocationState) => {
        let locationById = locationState.locations.filter((_) => _.id == locationId);
        if (locationById.length == 0) {
            return null;
        }
        return locationById[0];
    });
