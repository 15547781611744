import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Attribute } from "../models/attribute";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AttributeService {
    
    resourceType: string = 'attributes';
    resource$ = null;
    
    constructor(
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
    }
    
    getAll(): Observable<any> {
        return this.resource$.getList();
    }
    
    update(payload: Attribute) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }
    
    add(payload: Attribute) {
        return this.resource$.post(payload);
    }
    
    delete(id: number) {
        return this.restangular.one(this.resourceType, id).remove(id);
    }
}
