import { createAction, props } from "@ngrx/store";
import { Attribute } from "../../models/attribute";

export const loadAttributes = createAction(
    '[Attribute] Load all Attributes'
);

export const loadAttributesSuccess = createAction(
    '[Attribute] Load all Attributes Success',
    props<{ attributes: Attribute[] }>()
);

export const loadAttributesError = createAction(
    '[Attribute] Load Attributes Error',
    props<{ error: any }>()
);

export const invokeUpdateAttribute = createAction(
    '[Attribute] Update a Attribute',
    props<{updateAttribute: Attribute}>()
);

export const updateAttributeSuccess = createAction(
    '[Attribute] Update a Attribute Success',
    props<{updateAttribute: Attribute}>()
);

export const invokeAddAttribute = createAction(
    '[Attribute] Add a Attribute',
    props<{addAttribute: Attribute}>()
);

export const addAttributeSuccess = createAction(
    '[Attribute] Add a Attribute Success',
    props<{addAttribute: Attribute}>()
);

export const invokeDeleteAttribute = createAction(
    '[Attribute] Delete a Attribute',
    props<{deleteAttributeId: number}>()
);

export const deleteAttributeSuccess = createAction(
    '[Attribute] Delete a Attribute Success',
    props<{deleteAttributeId: number}>()
);

export const updateAttributesError = createAction(
    '[Attribute] Update Attributes Error',
    props<{ error: any }>()
);
