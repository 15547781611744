import { Component } from '@angular/core';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss']
})
export class LegacyComponent {
  title = 'syme-exctra';
}
