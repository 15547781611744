import { createAction, props } from "@ngrx/store";
import { Region } from "../../models/region";

export const loadRegions = createAction(
    '[Region] Load all Regions'
);

export const loadRegionsSuccess = createAction(
    '[Region] Load all Regions Success',
    props<{ regions: Region[] }>()
);

export const loadRegionsError = createAction(
    '[Region] Load Regions Error',
    props<{ error: any }>()
);

export const invokeUpdateRegion = createAction(
    '[Region] Update a Region',
    props<{updateRegion: Region}>()
);

export const updateRegionSuccess = createAction(
    '[Region] Update a Region Success',
    props<{updateRegion: Region}>()
);

export const invokeAddRegion = createAction(
    '[Region] Add a Region',
    props<{addRegion: Region}>()
);

export const addRegionSuccess = createAction(
    '[Region] Add a Region Success',
    props<{addRegion: Region}>()
);

export const invokeDeleteRegion = createAction(
    '[Region] Delete a Region',
    props<{deleteRegionId: number}>()
);

export const deleteRegionSuccess = createAction(
    '[Region] Delete a Region Success',
    props<{deleteRegionId: number}>()
);

export const updateRegionsError = createAction(
    '[Region] Update Regions Error',
    props<{ error: any }>()
);
