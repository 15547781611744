import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromProject from "./project.reducer";
import { ProjectShortState, ProjectState } from "./project.reducer";

export const selectProjectState = createFeatureSelector<fromProject.ProjectState>(
    fromProject.projectsFeatureKey
);

export const selectProjectShortState = createFeatureSelector<fromProject.ProjectShortState>(
    fromProject.projectsShortFeatureKey
);

export const selectAllProjects = createSelector(
    selectProjectState,
    ps => ps.projects
)

export const selectAllProjectsShort = createSelector(
    selectProjectShortState,
    ps => ps.projects
)

export const selectProjects = createFeatureSelector<ProjectState>('Projects');

export const selectProjectsShort = createFeatureSelector<ProjectShortState>('ProjectsShort');

export const selectProjectShortById = (projectId: number) => createSelector(
    selectProjectsShort, (projectShortState: ProjectShortState) => {
        let projectById = projectShortState.projects.filter((_) => _.id == projectId);
        if (projectById.length == 0) {
            return null;
        }
        return projectById[0];
    });

export const selectProjectById = (projectId: number) => createSelector(
    selectProjects, (projectState: ProjectState) => {
        let projectById = projectState.projects.filter((_) => _.id == projectId);
        if (projectById.length == 0) {
            return null;
        }
        return projectById[0];
    });
