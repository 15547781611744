import { createAction, props } from "@ngrx/store";
import { Employee } from "../../models/employee";

export const loadEmployees = createAction(
    '[Employee] Load all Employees'
);

export const loadEmployeesSuccess = createAction(
    '[Employee] Load all Employees Success',
    props<{ employees: Employee[] }>()
);

export const invokeUpdateEmployee = createAction(
    '[Employee] Update a Employee',
    props<{updateEmployee: Employee}>()
);

export const updateEmployeeSuccess = createAction(
    '[Employee] Update a Employee Success',
    props<{updateEmployee: Employee}>()
);

export const invokeAddEmployee = createAction(
    '[Employee] Add a Employee',
    props<{addEmployee: Employee}>()
);

export const addEmployeeSuccess = createAction(
    '[Employee] Add a Employee Success',
    props<{addEmployee: Employee}>()
);

export const invokeDeleteEmployee = createAction(
    '[Employee] Delete a Employee',
    props<{deleteEmployeeId: number}>()
);

export const deleteEmployeeSuccess = createAction(
    '[Employee] Delete a Employee Success',
    props<{deleteEmployeeId: number}>()
);


export const loadEmployeesError = createAction(
    '[Employee] Load Employees Error',
    props<{ error: any }>()
);

export const updateEmployeesError = createAction(
    '[Employee] Update Employees Error',
    props<{ error: any }>()
);

export const loadEmployeesShort = createAction(
    '[EmployeeShort] Load all Employees (short)'
);

export const loadEmployeesShortSuccess = createAction(
    '[EmployeeShort] Load all Employees (short) Success',
    props<{ employees: Employee[] }>()
);

export const invokeUpdateEmployeeShort = createAction(
    '[EmployeeShort] Update a Employee (short)',
    props<{updateEmployeeShort: Employee}>()
);

export const updateEmployeeShortSuccess = createAction(
    '[EmployeeShort] Update a Employee (short) Success',
    props<{updateEmployeeShort: Employee}>()
);

export const invokeSetEmployeeDeletedShort = createAction(
    '[EmployeeShort] Set a Employee on deleted (short)',
    props<{setEmployeeDeletedShort: Employee}>()
);

export const setEmployeeDeletedShortSuccess = createAction(
    '[EmployeeShort] Set a Employee on deleted (short) Success',
    props<{setEmployeeDeletedShort: Employee}>()
);

export const invokeAddEmployeeShort = createAction(
    '[EmployeeShort] Add a Employee (short)',
    props<{addEmployeeShort: Employee}>()
);

export const addEmployeeShortSuccess = createAction(
    '[EmployeeShort] Add a Employee (short) Success',
    props<{addEmployeeShort: Employee}>()
);

export const invokeDeleteEmployeeShort = createAction(
    '[EmployeeShort] Delete a Employee',
    props<{deleteEmployeeShortId: number}>()
);

export const deleteEmployeeShortSuccess = createAction(
    '[EmployeeShort] Delete a Employee Success',
    props<{deleteEmployeeShortId: number}>()
);


export const loadEmployeesShortError = createAction(
    '[EmployeeShort] Load Employees Error',
    props<{ error: any }>()
);

export const updateEmployeesShortError = createAction(
    '[EmployeeShort] Update Employees Error',
    props<{ error: any }>()
);



