import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EmployeeService } from "../../services/employee.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { EmployeesAppState } from "./employee.reducer";
import {
    loadEmployees,
    loadEmployeesSuccess,
    invokeUpdateEmployee,
    updateEmployeeSuccess,
    invokeAddEmployee,
    addEmployeeSuccess,
    invokeDeleteEmployee,
    deleteEmployeeSuccess,
    loadEmployeesShort,
    loadEmployeesShortSuccess,
    invokeUpdateEmployeeShort,
    updateEmployeeShortSuccess,
    invokeAddEmployeeShort,
    invokeDeleteEmployeeShort,
    deleteEmployeeShortSuccess,
    addEmployeeShortSuccess,
    invokeSetEmployeeDeletedShort, setEmployeeDeletedShortSuccess
} from "./employee.actions";
import { selectAllEmployees, selectAllEmployeesShort } from "./employee.selectors";
import { Employee } from "../../models/employee";

@Injectable()
export class EmployeeEffects {

    constructor(
        private employeeService: EmployeeService,
        private actions$: Actions,
        private store: Store<EmployeesAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    employeesLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadEmployees),
            withLatestFrom(this.store.pipe(select(selectAllEmployees))),
            mergeMap(([ , employeesFromStore ]: any) => {
                if (employeesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.employeeService.getAll()
                    .pipe(map((data: Employee[]) => loadEmployeesSuccess({employees: data})));
            })
        )
    );
    
    employeesLoadShort$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadEmployeesShort),
            withLatestFrom(this.store.pipe(select(selectAllEmployeesShort))),
            mergeMap(([ , employeesFromStore ]: any) => {
                if (employeesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.employeeService.getAllShort()
                    .pipe(map((data: Employee[]) => loadEmployeesShortSuccess({employees: data})));
            })
        )
    );

    updateEmployee$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateEmployee),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.employeeService.update(action.updateEmployee).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateEmployeeSuccess({ updateEmployee: data });
                    })
                );
            })
        );
    });
    
    updateEmployeeShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateEmployeeShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.employeeService.updateShort(action.updateEmployeeShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateEmployeeShortSuccess({ updateEmployeeShort: data });
                    })
                );
            })
        );
    });
    
    setEmployeeDeletedShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeSetEmployeeDeletedShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.employeeService.updateShort(action.setEmployeeDeletedShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return setEmployeeDeletedShortSuccess({ setEmployeeDeletedShort: data });
                    })
                );
            })
        );
    });

    addEmployee$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddEmployee),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.employeeService.add(action.addEmployee).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addEmployeeSuccess({ addEmployee: data });
                    })
                );
            })
        );
    });
    
    addEmployeeShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddEmployeeShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.employeeService.addShort(action.addEmployeeShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addEmployeeShortSuccess({ addEmployeeShort: data });
                    })
                );
            })
        );
    });

    deleteEmployee$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteEmployee),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.employeeService.delete(action.deleteEmployeeId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteEmployeeSuccess({ deleteEmployeeId: action.deleteEmployeeId });
                    })
                );
            })
        );
    });
    
    deleteEmployeeShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteEmployeeShort),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.employeeService.deleteShort(action.deleteEmployeeShortId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteEmployeeShortSuccess({ deleteEmployeeShortId: action.deleteEmployeeShortId });
                    })
                );
            })
        );
    });
}
