import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { map, Observable } from "rxjs";
import { Matrix } from "../models/matrix";

@Injectable({
    providedIn: 'root'
})
export class MatrixService {
    
    resourceType: string = 'matrices';
    resourceTypeShort: string = 'matrices/short';
    resource$ = null;
    resourceShort$ = null;
    
    constructor(
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
        this.resourceShort$ = this.restangular.all(this.resourceTypeShort);
    }
    
    
    getAllShort(): Observable<any> {
        return this.resourceShort$.getList();
    }
    
    addShort(payload: Matrix) {
        return this.resourceShort$.post(payload);
    }
    
    updateShort(payload: Matrix) {
        return this.restangular.one(this.resourceTypeShort, payload.id).customPUT(payload);
    }
    
    deleteShort(id: number): Observable<any> {
        return this.restangular.one(this.resourceTypeShort, id).remove(id);
    }
    
    getAll(): Observable<any> {
        return this.resource$.getList();
    }
    
    getById(id: number): Observable<any> {
        return this.resource$.get(id);
    }
    
    add(payload: Matrix) {
        return this.resource$.post(payload);
    }
    
    update(payload: Matrix) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }
    
    delete(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).remove(id);
    }
    
    savePosition(payload: any) {
        return this.restangular.one(this.resourceType, payload.matrixId).customGET('save-position', {query: payload});
    }
    
    copy(id: number, newName: string) {
        const payload = {id: id, newName: newName};
        return this.restangular.one(this.resourceType, id).customGET('copy', payload);
    }
}
