import { Injectable } from '@angular/core';
import { RouteInfo } from "./sidebar.metadata";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }
  
  getPermission(menuItem: RouteInfo) {
    const user = JSON.parse(localStorage.getItem('user'));
    return !(menuItem && menuItem.data && menuItem.data['roles'] && menuItem.data['roles'].indexOf(user.roles[0]) === -1);
  }
}
