import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromCountry from "./country.reducer";
import { CountryState } from "./country.reducer";

export const selectCountryState = createFeatureSelector<fromCountry.CountryState>(
    fromCountry.countriesFeatureKey
);

export const selectAllCountries = createSelector(
    selectCountryState,
    ls => ls.countries
);


