import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  form: FormGroup;
  requestSent: boolean = false;
  submitted:boolean = false;
  responseMessage: string = '';
  responseError: boolean = false;
  
  
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      private userService: UserService
  ) {
    this.form = this.fb.group({
      username: [ '', Validators.required],
      email: [ '', [Validators.required, Validators.email]]
    });
  }
  
  // On SignIn link click
  onSignIn() {
    this.router.navigate(['sign-in'], { relativeTo: this.route.parent });
  }
  
  get formControl() {
    return this.form.controls;
  }
  
  onSend() {
    this.submitted = true;
    if (this.form.valid) {
      this.requestSent = true;
      const val = this.form.value;
      
      this.userService.requestPassword(val)
          .subscribe({
            next: message => {
              this.responseMessage = message;
            },
            error: err => {
              this.responseMessage = err.data.message;
              this.responseError = true;
            }
          });
    }
  }
  
  onBack() {
    this.resetRequest();
    this.router.navigate(['forgot-password'], { relativeTo: this.route.parent });
  }
  
  resetRequest() {
    this.requestSent = false;
    this.responseError = false;
  }
}
