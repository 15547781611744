import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../../services/user.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { UsersAppState } from "./user.reducer";
import {
    loadUsers,
    loadUsersSuccess,
    invokeUpdateUser,
    updateUserSuccess,
    invokeAddUser,
    addUserSuccess, invokeDeleteUser, deleteUserSuccess
} from "./user.actions";
import { selectAllUsers } from "./user.selectors";
import { User } from "../../models/user";

@Injectable()
export class UserEffects {

    constructor(
        private userService: UserService,
        private actions$: Actions,
        private store: Store<UsersAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    usersLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadUsers),
            withLatestFrom(this.store.pipe(select(selectAllUsers))),
            mergeMap(([ , usersFromStore ]: any) => {
                if (usersFromStore.length > 0) {
                    return EMPTY;
                }
                return this.userService.getAll()
                    .pipe(map((data: User[]) => loadUsersSuccess({users: data})));
            })
        )
    );

    updateUser$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateUser),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.userService.update(action.updateUser).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateUserSuccess({ updateUser: data });
                    })
                );
            })
        );
    });

    addUser$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddUser),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.userService.add(action.addUser).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addUserSuccess({ addUser: data });
                    })
                );
            })
        );
    });

    deleteUser$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteUser),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.userService.delete(action.deleteUserId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteUserSuccess({ deleteUserId: action.deleteUserId });
                    })
                );
            })
        );
    });
}
