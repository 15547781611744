import { Injectable } from '@angular/core';
import { LocationService } from "./location.service";
import { User } from "../models/user";
import { from, Observable, of, switchMap } from "rxjs";
import { Role } from "../models/role";
import { config } from "../app.config";
import { AuthService } from "../modules/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class RouterInfoService {
    
    constructor(
        private authService: AuthService,
        private locationService: LocationService
    ) {
    }
    
    getAdditionalInfo(user: User): Observable<any> {
        return this.locationService.getById(user.locationId).pipe(
            switchMap((location): any => {
                const latestProject = location.projects.findLast(item => item.active);
                return of(latestProject);
            }));
    }
    
    getRouteByUserRole() {
        const user = this.authService.userValue;
        
        let route = null;
        
        switch (user?.roles[0]) {
            case Role.FilialeMentor:
                route = this.getAdditionalInfo(user).pipe(
                    switchMap(project => {
                        return of(`reporting/location/${project.id}/${user.locationId}`);
                    }));
                break;
            case Role.MultipleMentor:
                route = of('reporting/selection');
                break;
            case Role.MasterGuide:
            case Role.CustomerAdmin:
                route = of('reporting/extended');
                break;
            default:
                route = of(config.route.default);
        }
        
        return route;
    }
}
