import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AttributeService } from "../../services/attribute.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { AttributesAppState } from "./attribute.reducer";
import {
    loadAttributes,
    loadAttributesSuccess,
    invokeUpdateAttribute,
    updateAttributeSuccess,
    invokeAddAttribute,
    addAttributeSuccess, invokeDeleteAttribute, deleteAttributeSuccess
} from "./attribute.actions";
import { selectAllAttributes } from "./attribute.selectors";
import { Attribute } from "../../models/attribute";

@Injectable()
export class AttributeEffects {

    constructor(
        private attributeService: AttributeService,
        private actions$: Actions,
        private store: Store<AttributesAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    attributesLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadAttributes),
            withLatestFrom(this.store.pipe(select(selectAllAttributes))),
            mergeMap(([ , attributesFromStore ]: any) => {
                if (attributesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.attributeService.getAll()
                    .pipe(map((data: Attribute[]) => loadAttributesSuccess({attributes: data})));
            })
        )
    );

    updateAttribute$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateAttribute),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.attributeService.update(action.updateAttribute).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateAttributeSuccess({ updateAttribute: data });
                    })
                );
            })
        );
    });

    addAttribute$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddAttribute),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.attributeService.add(action.addAttribute).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addAttributeSuccess({ addAttribute: data });
                    })
                );
            })
        );
    });

    deleteAttribute$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteAttribute),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.attributeService.delete(action.deleteAttributeId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteAttributeSuccess({ deleteAttributeId: action.deleteAttributeId });
                    })
                );
            })
        );
    });
}
