import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAttribute from "./attribute.reducer";
import { AttributeState } from "./attribute.reducer";

export const selectAttributeState = createFeatureSelector<fromAttribute.AttributeState>(
    fromAttribute.attributesFeatureKey
);

export const selectAllAttributes = createSelector(
    selectAttributeState,
    ls => ls.attributes
)

export const selectAttributes = createFeatureSelector<AttributeState>('Attributes');

export const selectAttributeById = (attributeId: number) => createSelector(
    selectAttributes, (attributeState: AttributeState) => {
        let attributeById = attributeState.attributes.filter((_) => _.id == attributeId);
        if (attributeById.length == 0) {
            return null;
        }
        return attributeById[0];
    });
