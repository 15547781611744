import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromMatrix from "./matrix.reducer";
import { MatrixShortState, MatrixState } from "./matrix.reducer";

export const selectMatrixState = createFeatureSelector<fromMatrix.MatrixState>(
    fromMatrix.matricesFeatureKey
);

export const selectMatrixShortState = createFeatureSelector<fromMatrix.MatrixShortState>(
    fromMatrix.matricesShortFeatureKey
);

export const selectAllMatrices = createSelector(
    selectMatrixState,
    ps => ps.matrices
)

export const selectAllMatricesShort = createSelector(
    selectMatrixShortState,
    ps => ps.matrices
)

export const selectMatrices = createFeatureSelector<MatrixState>('Matrices');

export const selectMatricesShort = createFeatureSelector<MatrixShortState>('MatricesShort');

export const selectMatrixShortById = (matrixId: number) => createSelector(
    selectMatricesShort, (matrixShortState: MatrixShortState) => {
        let matrixById = matrixShortState.matrices.filter((_) => _.id == matrixId);
        if (matrixById.length == 0) {
            return null;
        }
        return matrixById[0];
    });

export const selectMatrixById = (matrixId: number) => createSelector(
    selectMatrices, (matrixState: MatrixState) => {
        let matrixById = matrixState.matrices.filter((_) => _.id == matrixId);
        if (matrixById.length == 0) {
            return null;
        }
        return matrixById[0];
    });
