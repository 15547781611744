import { Routes } from '@angular/router';
import { AuthGuard } from "../../modules/auth/auth-guard.service";
import { Role } from "../../models/role";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.MasterGuide] },
        loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'evaluation',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.FilialeMentor, Role.MultipleMentor, Role.MasterGuide] },
        loadChildren: () => import('../../modules/evaluation/evaluation.module').then(m => m.EvaluationModule)
    },
    {
        path: 'reporting',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.FilialeMentor, Role.MultipleMentor, Role.MasterGuide] },
        loadChildren: () => import('../../modules/reporting/reporting.module').then(m => m.ReportingModule)
    },
    {
        path: 'achievements',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.FilialeMentor, Role.MasterGuide, Role.MultipleMentor] },
        loadChildren: () => import('../../modules/achievement/achievement.module').then(m => m.AchievementModule)
    },
    {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.FilialeMentor, Role.MasterGuide, Role.MultipleMentor] },
        loadChildren: () => import('../../modules/misc/misc.module').then(m => m.MiscModule)
    }
];
