  <!-- Sidebar Content starts -->
<div class="sidebar sidebar-wrapper" >
        <!-- Sidebar Header starts -->
        <div class="sidebar-header">
            <div>
                <img src="assets/images/syme-logo.png" class="logo-icon" alt="logo icon" *ngIf="!sidebarservice.getSidebarState()" >
                <img src="assets/images/syme-logo-folded.png" class="logo-icon" alt="logo icon" *ngIf="sidebarservice.getSidebarState()" >
            </div>
            <div class="toggle-icon ms-auto">
                <i *ngIf="!sidebarservice.getSidebarState()" (click)="toggleSidebar()"  class='bx bx-arrow-to-left'></i>
                <i *ngIf="sidebarservice.getSidebarState()" (click)="toggleSidebar()"  class='bx bx-arrow-to-right'></i>
            </div>
        </div>
        <!-- Sidebar Header Ends -->
    <div class="sidebar-content" [perfectScrollbar]="">
        <ul class="navigation">
            <!-- First level menu -->
            <ng-container *ngFor="let menuItem of superMenuItems">
                <li *ngIf="sidebarservice.getPermission(menuItem)"  [ngClass]="[menuItem.class]" [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a [routerLink]="menuItem.class === '' ? [menuItem.path] : null" [ngClass]="menuItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
                        <i *ngIf="menuItem.matIcon" class="material-icons">{{menuItem.matIcon}}</i>
                        <i *ngIf="menuItem.icon" [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title|translate}}</span>
                        <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>
                    <ng-template #externalLinkBlock>
                        <a [href]="[menuItem.path]" target="_blank">
                            <i [ngClass]="[menuItem.icon]"></i>
                            <span class="menu-title">{{menuItem.title|translate}}</span>
                            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                        </a>
                    </ng-template>
                    <!-- Second level menu (i.e. customer) -->
                    <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
                        <ng-container *ngFor="let menuSubItem of menuItem.submenu">
                            <li *ngIf="sidebarservice.getPermission(menuSubItem)" [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'" [ngClass]="[menuSubItem.class]">
                                <ng-container *ngIf="!user.isSuperAdmin">
                                    <a [routerLink]="menuSubItem.class === '' ? [menuSubItem.path] : null" [ngClass]="menuItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock" (click)="loadLocationsSubmenu(menuSubItem)">
                                        <i *ngIf="menuSubItem.matIcon" class="material-icons">{{menuSubItem.matIcon}}</i>
                                        <i *ngIf="menuSubItem.icon" [ngClass]="[menuSubItem.icon]"></i>
                                        <span class="sub-menu-title">{{menuSubItem.title|translate}}</span>
                                        <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="user.isSuperAdmin && menuItem.hasOwnProperty('id')">
                                    <a [routerLink]="null" [ngClass]="menuItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                                        <i *ngIf="menuSubItem.matIcon" class="material-icons">{{menuSubItem.matIcon}}</i>
                                        <i *ngIf="menuSubItem.icon" [ngClass]="[menuSubItem.icon]"></i>
                                        <span class="sub-menu-title">{{menuSubItem.title|translate}}</span>
                                        <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="user.isSuperAdmin && !menuItem.hasOwnProperty('id')">
                                    <a [routerLink]="menuSubItem.class === '' ? [menuSubItem.path] : null" [ngClass]="menuItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                                        <i *ngIf="menuSubItem.matIcon" class="material-icons">{{menuSubItem.matIcon}}</i>
                                        <i *ngIf="menuSubItem.icon" [ngClass]="[menuSubItem.icon]"></i>
                                        <span class="sub-menu-title">{{menuSubItem.title|translate}}</span>
                                        <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                    </a>
                                </ng-container>
                                <ng-template #externalSubLinkBlock>
                                    <a [href]="[menuSubItem.path]" target="_blank">
                                        <i [ngClass]="[menuSubItem.icon]"></i>
                                        <span class="sub-menu-title">{{menuSubItem.title|translate}}</span>
                                        <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                                    </a>
                                </ng-template>
                                <!-- Third level menu (i.e. projects) -->
                                <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                                    <li *ngFor="let menuSubsubItem of menuSubItem.submenu" [routerLinkActive]="menuSubsubItem.submenu.length > 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact: true}"
                                        [ngClass]="[menuSubsubItem.class]">
                                        <ng-container *ngIf="!user.isSuperAdmin && (menuSubsubItem.path || menuSubsubItem.submenu.length > 0)">
                                            <a [routerLink]="menuSubsubItem.submenu.length > 0 ? null : [menuSubsubItem.path]" [ngClass]="menuSubsubItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                                                <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                <span class="sub-menu-title level-4">{{menuSubsubItem.title|translate}}</span>
                                                <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="user.isSuperAdmin && menuItem.hasOwnProperty('id')">
                                            <a [routerLink]="null" [ngClass]="menuSubsubItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock" (click)="loadLocationsSubmenu(menuSubsubItem)">
                                                <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                <span class="sub-menu-title">{{menuSubsubItem.title|translate}}</span>
                                                <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="user.isSuperAdmin && !menuItem.hasOwnProperty('id')">
                                            <a [routerLink]="[menuSubsubItem.path]" [ngClass]="menuSubsubItem.class === '' ? '' : 'cursor-pointer' " *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock" (click)="loadLocationsSubmenu(menuSubsubItem)">
                                                <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                <span class="sub-menu-title">{{menuSubsubItem.title|translate}}</span>
                                                <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                            </a>
                                        </ng-container>
                                        <ng-template #externalSubSubLinkBlock>
                                            <a [href]="[menuSubsubItem.path]" target="_blank">
                                                <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                <span class="sub-menu-title">{{menuSubsubItem.title|translate}}</span>
                                                <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                            </a>
                                        </ng-template>
                                        <!-- Forth level menu -->
                                        <ul class="menu-content" *ngIf="menuSubsubItem.submenu.length > 0">
                                            <li *ngFor="let menuSubThirdItem of menuSubsubItem.submenu" [routerLinkActive]="menuSubThirdItem.submenu.length > 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact: true}"
                                                [ngClass]="[menuSubThirdItem.class]">
                                                <a [routerLink]="[menuSubThirdItem.path]">
                                                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                                                    <span class="sub-menu-title level-4">{{menuSubThirdItem.title|translate}}</span>
                                                    <span *ngIf="menuSubThirdItem.badge != '' " [ngClass]="[menuSubThirdItem.badgeClass]">{{menuSubThirdItem.badge}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>
    <!-- Sidebar Content Ends -->
</div>
