import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import _ from 'lodash';
import { Router } from "@angular/router";
import { AuthService } from "../modules/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class BasicService {
    
    user;
    
    constructor(
        private authService: AuthService,
        private formatter: NgbDateParserFormatter,
        private router: Router
    ) {
        this.user = this.authService.userValue;
    }
    
    boolToUpperCase(input: boolean): string {
        return input ? 'TRUE' : 'FALSE';
    }
    
    strToUpperCase(input: string): string {
        return input ? input.toUpperCase() : null;
    }
    
    formatDateForPersistence(input: NgbDateStruct): string {
        if (!input) {
            return null;
        }
        return this.formatter.format(input)
    }
    
    formatDecimal(input: string, withLocale: boolean = true) {
        let locale = `${this.user.lang}-${this.user.lang.toUpperCase()}`;
        if (!withLocale) {
            locale = 'en-US';
        }
        return parseFloat(input).toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    
    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    isPermitted(features: string[], type: string) {
        return _.includes(features, type);
    }
    
    reloadComponent(url) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([ url ]);
    }
}
