import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RegionService } from "../../services/region.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { RegionsAppState } from "./region.reducer";
import {
    loadRegions,
    loadRegionsSuccess,
    invokeUpdateRegion,
    updateRegionSuccess,
    invokeAddRegion,
    addRegionSuccess, invokeDeleteRegion, deleteRegionSuccess
} from "./region.actions";
import { selectAllRegions } from "./region.selectors";
import { Region } from "../../models/region";

@Injectable()
export class RegionEffects {

    constructor(
        private regionService: RegionService,
        private actions$: Actions,
        private store: Store<RegionsAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    regionsLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadRegions),
            withLatestFrom(this.store.pipe(select(selectAllRegions))),
            mergeMap(([ , regionsFromStore ]: any) => {
                if (regionsFromStore.length > 0) {
                    return EMPTY;
                }
                return this.regionService.getAll()
                    .pipe(map((data: Region[]) => loadRegionsSuccess({regions: data})));
            })
        )
    );

    updateRegion$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateRegion),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.regionService.update(action.updateRegion).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateRegionSuccess({ updateRegion: data });
                    })
                );
            })
        );
    });

    addRegion$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddRegion),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.regionService.add(action.addRegion).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addRegionSuccess({ addRegion: data });
                    })
                );
            })
        );
    });

    deleteRegion$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteRegion),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.regionService.delete(action.deleteRegionId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteRegionSuccess({ deleteRegionId: action.deleteRegionId });
                    })
                );
            })
        );
    });
}
