import { createReducer, on } from "@ngrx/store";
import * as RegionActions from "./region.actions";
import { Region } from "../../models/region";

export const regionsFeatureKey = 'Regions';

export interface RegionsAppState {
    [regionsFeatureKey]: RegionState;
}

export interface RegionState {
    regions: Region[];
    selectedRegion: Region|null;
    isLoading: boolean;
}

export const initialRegionState: RegionState =  {
    regions: [],
    selectedRegion: null,
    isLoading: false
}


export const RegionReducer = createReducer(
    initialRegionState,
    on(RegionActions.loadRegions, (state): RegionState => {
        const regions = [] as Region[];
        const isLoading = true;
        return {...state, regions, isLoading};
    }),
    on(RegionActions.loadRegionsSuccess, (state, action): RegionState => {
        const regions = action.regions;
        const isLoading = false;
        return {...state, regions, isLoading};
    }),
    on(RegionActions.updateRegionSuccess, (state: RegionState, { updateRegion }): RegionState => {
        let regions = state.regions.filter((_) => _.id != updateRegion.id);
        regions.unshift(updateRegion);
        const isLoading = false;
        return {...state, regions, isLoading};
    }),
    on(RegionActions.addRegionSuccess, (state: RegionState, { addRegion }): RegionState => {
        let regions = [...state.regions];
        regions.unshift(addRegion);
        const isLoading = false;
        return {...state, regions, isLoading};
    }),
    on(RegionActions.deleteRegionSuccess, (state: RegionState, { deleteRegionId }): RegionState => {
        let regions = state.regions.filter((_) => _.id != deleteRegionId);
        const isLoading = false;
        return {...state, regions, isLoading};
    })
);
