import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs';

import { environment } from "../../environments/environment"
import { Location } from '../models/location'
import { List } from "../models/list";
import { Restangular } from "@ausy_gl/ngx-restangular";


const baseUrl = `${environment.apiHost}/locations`

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    
    resourceType: string = 'locations';
    resource$ = null;

    constructor(
        private http: HttpClient,
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
    }

    getAll(): Observable<any> {
        return this.http.get<List<Location>>(baseUrl).pipe(
            map(res => res['hydra:member'])
        )
    }

    update(payload: Location) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }

    add(payload: Location) {
        return this.http.post(`${baseUrl}`, payload);
    }

    delete(id: number) {
        return this.http.delete(`${baseUrl}/${id}`);
    }
    
    getById(id: number): Observable<any> {
        return this.resource$.get(id);
    }
    
    /**
     * @param id locationId
     */
    getEmployees(id: number) {
        return this.restangular.one(this.resourceType, id).customGETLIST('employees', {deleted: false});
    }
    
    /**
     * @param id locationId
     */
    getEmployeesWithEvaluation(id: number) {
        return this.restangular.one(this.resourceType, id).customGETLIST('employees-evaluation', {enabled: true, deleted: false});
    }
    
    /**
     * @param id locationId
     */
    getUsers(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).customGETLIST('users');
    }
}
