import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Region } from "../models/region";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RegionService {

    resourceType: string = 'regions';
    resource$ = null;

    constructor(
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
    }

    getAll(): Observable<any> {
        return  this.resource$.getList();
    }

    update(payload: Region) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }

    add(payload: Region) {
        return this.resource$.post(payload);
    }

    delete(id: number) {
        return this.restangular.one(this.resourceType, id).remove(id);
    }

    getById(id: number): Observable<any> {
        return this.resource$.get(id);
    }
}
