import { createReducer, on } from "@ngrx/store";
import * as ProjectActions from "./project.actions";
import { Project } from "../../models/project";

export const projectsFeatureKey = 'Projects';
export const projectsShortFeatureKey = 'ProjectsShort';

export interface ProjectsAppState {
    [projectsFeatureKey]: ProjectState;
}

export interface ProjectsShortAppState {
    [projectsShortFeatureKey]: ProjectShortState;
}

export interface ProjectState {
    projects: Project[];
    selectedProject: Project|null;
    isLoading: boolean;
}

export const initialProjectState: ProjectState =  {
    projects: [],
    selectedProject: null,
    isLoading: false
}

export interface ProjectShortState {
    projects: Project[];
    selectedProject: Project|null;
    isLoading: boolean;
}

export const initialProjectShortState: ProjectShortState =  {
    projects: [],
    selectedProject: null,
    isLoading: false
}


export const ProjectShortReducer = createReducer(
    initialProjectShortState,
    on(ProjectActions.loadProjectsShort, (state): ProjectShortState => {
        const projects = [] as Project[];
        const isLoading = true;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.loadProjectsShortSuccess, (state, action): ProjectShortState => {
        const projects = action.projects;
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.updateProjectShortSuccess, (state: ProjectShortState, { updateProjectShort }): ProjectShortState => {
        let projects = state.projects.filter((_) => _.id != updateProjectShort.id);
        projects.unshift(updateProjectShort);
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.addProjectShortSuccess, (state: ProjectShortState, { addProjectShort }): ProjectShortState => {
        let projects = [...state.projects];
        projects.unshift(addProjectShort);
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.deleteProjectShortSuccess, (state: ProjectShortState, { deleteProjectShortId }): ProjectShortState => {
        let projects = state.projects.filter((_) => _.id != deleteProjectShortId);
        const isLoading = false;
        return {...state, projects, isLoading};
    })
);

export const ProjectReducer = createReducer(
    initialProjectState,
    on(ProjectActions.loadProjects, (state): ProjectState => {
        const projects = [] as Project[];
        const isLoading = true;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.loadProjectsSuccess, (state, action): ProjectState => {
        const projects = action.projects;
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.updateProjectSuccess, (state: ProjectState, { updateProject }): ProjectState => {
        let projects = state.projects.filter((_) => _.id != updateProject.id);
        projects.unshift(updateProject);
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.addProjectSuccess, (state: ProjectState, { addProject }): ProjectState => {
        let projects = [...state.projects];
        projects.unshift(addProject);
        const isLoading = false;
        return {...state, projects, isLoading};
    }),
    on(ProjectActions.deleteProjectSuccess, (state: ProjectState, { deleteProjectId }): ProjectState => {
        let projects = state.projects.filter((_) => _.id != deleteProjectId);
        const isLoading = false;
        return {...state, projects, isLoading};
    })
);
