import { createReducer, on } from "@ngrx/store";
import * as EmployeeActions from "./employee.actions";
import { Employee } from "../../models/employee";

export const employeesFeatureKey = 'Employees';
export const employeesShortFeatureKey = 'EmployeesShort';

export interface EmployeesAppState {
    [employeesFeatureKey]: EmployeeState;
}

export interface EmployeesShortAppState {
    [employeesShortFeatureKey]: EmployeeShortState;
}

export interface EmployeeState {
    employees: Employee[];
    selectedEmployee: Employee|null;
    isLoading: boolean;
}

export const initialEmployeeState: EmployeeState =  {
    employees: [],
    selectedEmployee: null,
    isLoading: false
}

export interface EmployeeShortState {
    employees: Employee[];
    selectedEmployee: Employee|null;
    isLoading: boolean;
}

export const initialEmployeeShortState: EmployeeShortState =  {
    employees: [],
    selectedEmployee: null,
    isLoading: false
}


export const EmployeeShortReducer = createReducer(
    initialEmployeeShortState,
    on(EmployeeActions.loadEmployeesShort, (state): EmployeeShortState => {
        const employees = [] as Employee[];
        const isLoading = true;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.loadEmployeesShortSuccess, (state, action): EmployeeShortState => {
        const employees = action.employees;
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.setEmployeeDeletedShortSuccess, (state: EmployeeShortState, { setEmployeeDeletedShort }): EmployeeShortState => {
        let employees = state.employees.filter((_) => _.id != setEmployeeDeletedShort.id);
        // employees.unshift(updateEmployeeShort);
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.updateEmployeeShortSuccess, (state: EmployeeShortState, { updateEmployeeShort }): EmployeeShortState => {
        let employees = state.employees.filter((_) => _.id != updateEmployeeShort.id);
        employees.unshift(updateEmployeeShort);
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.addEmployeeShortSuccess, (state: EmployeeShortState, { addEmployeeShort }): EmployeeShortState => {
        let employees = [...state.employees];
        employees.unshift(addEmployeeShort);
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.deleteEmployeeShortSuccess, (state: EmployeeShortState, { deleteEmployeeShortId }): EmployeeShortState => {
        let employees = state.employees.filter((_) => _.id != deleteEmployeeShortId);
        const isLoading = false;
        return {...state, employees, isLoading};
    })
);

export const EmployeeReducer = createReducer(
    initialEmployeeState,
    on(EmployeeActions.loadEmployees, (state): EmployeeState => {
        const employees = [] as Employee[];
        const isLoading = true;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.loadEmployeesSuccess, (state, action): EmployeeState => {
        const employees = action.employees;
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.updateEmployeeSuccess, (state: EmployeeState, { updateEmployee }): EmployeeState => {
        let employees = state.employees.filter((_) => _.id != updateEmployee.id);
        employees.unshift(updateEmployee);
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.addEmployeeSuccess, (state: EmployeeState, { addEmployee }): EmployeeState => {
        let employees = [...state.employees];
        employees.unshift(addEmployee);
        const isLoading = false;
        return {...state, employees, isLoading};
    }),
    on(EmployeeActions.deleteEmployeeSuccess, (state: EmployeeState, { deleteEmployeeId }): EmployeeState => {
        let employees = state.employees.filter((_) => _.id != deleteEmployeeId);
        const isLoading = false;
        return {...state, employees, isLoading};
    })
);
