import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Observable } from "rxjs";
import { Project } from "../models/project";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  
  resourceType: string = 'projects';
  resourceTypeShort: string = 'projects/short';
  resource$ = null;
  resourceShort$ = null;

  constructor(
      private restangular: Restangular
  ) {
    this.resource$ = this.restangular.all(this.resourceType);
    this.resourceShort$ = this.restangular.all(this.resourceTypeShort);
  }
  
  
  
  getAllShort(): Observable<any> {
    return this.resourceShort$.getList();
  }
  
  addShort(payload: Project) {
    return this.resourceShort$.post(payload);
  }
  
  updateShort(payload: Project) {
    return this.restangular.one(this.resourceTypeShort, payload.id).customPUT(payload);
  }
  
  deleteShort(id: number): Observable<any> {
    return this.restangular.one(this.resourceTypeShort, id).remove(id);
  }
  
  getAll(): Observable<any> {
    return this.resource$.getList();
  }
  
  add(payload: Project) {
    return this.resource$.post(payload);
  }
  
  update(payload: Project) {
    return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
  }
  
  delete(id: number): Observable<any> {
    return this.restangular.one(this.resourceType, id).remove(id);
  }
  
  getById(id: number): Observable<any> {
    return this.resource$.get(id);
  }
  
  getActive(): Observable<any> {
    return this.resource$.customGETLIST( '', {active: true});
  }
  
  getLocations(id: number) {
    return this.restangular.one(this.resourceType, id).customGETLIST('locations');
  }
  
  getLocationsByEvaluations(id: number): Observable<any> {
    return this.restangular.one(this.resourceType, id).customGETLIST('locations-from-evaluations');
  }
  
  getYearlyStatisticsData(id: number, params) : Observable<any> {
    return this.restangular.one(this.resourceType, id).customGETLIST('yearly-specialists-statistic', params);
  }
}
