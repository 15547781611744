import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { PasswordRepeatValidator } from "../../../services/validators/passwordRepeat.validator";
import { TranslateService } from "@ngx-translate/core";
import { config } from "../../../app.config";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  currentLang: string = 'de';
  form: FormGroup;
  requestSent: boolean = false;
  submitted: boolean = false;
  passwordMinLength: number = config.password.minLength;
  responseMessage: string = '';
  responseError: boolean = false;
  
  
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      private translate: TranslateService,
      private userService: UserService
  ) {
    translate.setDefaultLang(this.currentLang);
    translate.use(this.currentLang);
    
    this.route.queryParams
        .subscribe(params => {
              this.form = this.fb.group({
                resetToken: [params['token'], Validators.required],
                password: ['', [
                    Validators.required,
                    Validators.minLength(this.passwordMinLength),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&§()=\\[\\]{}_\\-#:;\\+~<>])[A-Za-z\\d@$!%*?&§()=\\[\\]{}_\\-#:;\\+~<>]{8,}$')
                ]],
                password_repeat: ['', Validators.required]
              }, {
                validators: PasswordRepeatValidator.checkPasswordsAreTheSame
              });
        });
  }
  
  get formControl() {
    return this.form.controls;
  }
  
  onSend() {
    this.submitted = true;
    if (this.form.valid) {
      this.requestSent = true;
      const val = this.form.value;
      
      this.userService.resetPassword({password: val.password, token: val.resetToken})
          .subscribe({
            next: message => {
              this.responseMessage = message;
            },
            error: err => {
              this.responseMessage = err.data.message;
              this.responseError = true;
            }
          });
    } else {
      console.log(this.formControl['password'].errors, this.form.errors);
    }
  }
  
  onBack() {
    this.resetRequest();
    this.router.navigate(['login'], { relativeTo: this.route.parent });
  }
  
  resetRequest() {
    this.requestSent = false;
    this.responseError = false;
  }

}
