import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "./modules/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { filter, mergeMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { config } from "./app.config";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {
    title = 'syme-exctra'
    loggedIn: boolean = false
    
    constructor(
        public authService: AuthService,
        private _router: Router,
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
    ) {
        let currentLang = 'de';
        translate.addLangs([ 'de', 'fr', 'it' ]);
        translate.setDefaultLang(currentLang);
    }
    
    ngOnInit() {
        this.routerTitle();
    }
    
    routerTitle() {
        this._router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
             )
            .subscribe(event => {
                const title = event['title'];
                if (title !== undefined) {
                    if (title.indexOf('translations') !== -1) {
                        this.translate.get(title).subscribe((term) => {
                            this.titleService.setTitle(this.translate.instant(`${term}${config.route.titleSuffix}`));
                        });
                    } else {
                        this.titleService.setTitle(this.translate.instant(`${title}${config.route.titleSuffix}`));
                    }
                }
            });
    }
    
    
    logout() {
        this.authService.logout()
        this._router.navigateByUrl('/login')
    }
}
