import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "./sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { select, Store } from "@ngrx/store";
import { selectAllCustomers } from "../../stores/customers/customer.selectors";
import { loadCustomers } from "../../stores/customers/customer.actions";
import { selectAllProjectsShort, selectProjectShortById } from "../../stores/projects/project.selectors";
import { loadProjectsShort } from "../../stores/projects/project.actions";
import { combineLatest, Observable, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { RouteInfo } from "./sidebar.metadata";
import { User } from "../../models/user";
import { AuthService } from "../../modules/auth/auth.service";
import { RouterInfoService } from "../../services/router-info.service";
import $ from "jquery";
import _ from "lodash";
import { orderBy } from "natural-orderby";
import { selectAllLocations, selectLocationById } from "../../stores/locations/location.selectors";
import { loadEmployeesShort } from "../../stores/employees/employee.actions";
import { ProjectService } from "../../services/project.service";
import { CustomerService } from '../../services/customer.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    superMenuItems: any[];
    collapsed: boolean = false;
    
    customers$= this.store.select(selectAllCustomers);
    projects$ = this.store.select(selectAllProjectsShort);
    // locations$ = this.store.select(selectAllLocations);
    
    user: User = null;
    // allLocations = [];
    

    constructor(
        public sidebarservice: SidebarService,
        private router: Router,
        private translate: TranslateService,
        private store: Store,
        private routerInfoService: RouterInfoService,
        private authService: AuthService,
        private projectService: ProjectService,
        private customerService: CustomerService
    )
    {
        translate.use(localStorage.getItem('lang'));
        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {

                this.toggleSidebar();
                // Hide loading indicator

            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }


    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

        if ($(".wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $(".wrapper").removeClass("nav-collapsed");
            $(".sidebar-wrapper").unbind( "hover");
        } else {
            $(".wrapper").addClass("nav-collapsed");
            $(".sidebar-wrapper").hover(
                function () {
                    $(".wrapper").addClass("sidebar-hovered");
                },
                function () {
                    $(".wrapper").removeClass("sidebar-hovered");
                }
            )

        }

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }


    ngOnInit() {
        this.user = this.authService.userValue;
        
        this.store.dispatch(loadCustomers());
        this.store.dispatch(loadProjectsShort());
        // this.store.dispatch(loadLocations());
        this.store.dispatch(loadEmployeesShort());
        
        const staticItems: RouteInfo[] = JSON.parse(JSON.stringify(ROUTES));
        this.setHomeLink(staticItems);
        
        if (this.user.isSuperAdmin === true) {
            const customerObservable = this.customers$;
            const projectObservable = this.projects$;
            // const locationObservable = this.locations$;
            
            combineLatest([ customerObservable, projectObservable ])
                .subscribe(([ customers, projects ]) => {
                    if (customers.length > 0 && projects.length > 0) {
                        // this.allLocations = locations;
                        staticItems.forEach(item => {
                            if (item.hasOwnProperty('id') && item.id === 'evaluationMenuItem' && item.submenu.length === 0) {
                                const customerSubItems = this.prepareCustomerSubmenu(customers, projects, item);
                                customerSubItems.forEach(customerSubItem => {
                                    if (customerSubItem) {
                                        item.submenu.push(customerSubItem);
                                    }
                                });
                            }
                            if (item.hasOwnProperty('id') && item.id === 'reportingMenuItem' && item.submenu.length === 0) {
                                const customerSubItems = this.prepareCustomerSubmenu(customers, projects, item);
                                customerSubItems.forEach(customerSubItem => {
                                    if (customerSubItem) {
                                        item.submenu.push(customerSubItem);
                                    }
                                });
                            }
                            
                            if (item.hasOwnProperty('id') && item.id === 'achievementMenuItem' && item.submenu.length === 0) {
                                const customerSubItems = this.prepareCustomerSubmenu(customers, projects, item);
                                customerSubItems.forEach(customerSubItem => {
                                    if (customerSubItem) {
                                        item.submenu.push(customerSubItem);
                                    }
                                });
                            }
                        });
                    }
                    
                });
        } else {
            const projectObservable = this.projects$;
            projectObservable
                .pipe(
                    map(items => items.filter(item => item.customer.id == this.user.customerId && item.active))
                )
                .subscribe(projects => {
                    if (projects.length > 0) {
                        staticItems.forEach(item => {
                            if (item.hasOwnProperty('id') && item.submenu.length === 0) {
                                projects.forEach(project=> {
                                    const projectItem = { path: '', title: project.name, icon: '', matIcon: '', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [], dataId: project.id, parent: item };
                                    item.submenu.push(projectItem);
                                });
                            }
                        });
                    }
                });
        }
        
        this.superMenuItems = staticItems.filter(menuItem => menuItem);
        $.getScript('./assets/js/app-sidebar.js');
        
        if (_.includes(['ROLE_FILIALE_MENTOR', 'ROLE_MULTIPLE_MENTOR'], this.user.roles[0]) && !this.getSideBarState()) {
            setTimeout(() => {
                this.toggleSidebar();
            }, 1000);
        } else {
            if (!_.includes(['ROLE_FILIALE_MENTOR', 'ROLE_MULTIPLE_MENTOR'], this.user.roles[0]) && this.getSideBarState()) {
                setTimeout(() => {
                    this.toggleSidebar();
                }, 1000);
            }
        }
    }
    
    loadLocationsSubmenu(menuItem: RouteInfo) {
        if (menuItem.dataId && menuItem.submenu.length == 0 && menuItem.class !== 'no-locations-loading') {
            if (menuItem.parent && menuItem.parent.id == 'evaluationMenuItem') {
                let customerId$: Observable<any> = of(this.user.customerId);
                if (this.user.roles[0] == 'ROLE_SUPER_ADMIN') {
                    customerId$ = this.store.pipe(select(selectProjectShortById(menuItem.dataId))).pipe(
                        switchMap(project => {
                            return of(project?.customer.id);
                        })
                    );
                }
                const customerLocationSubscription = customerId$.subscribe(customerId => {
                    this.customerService.getLocations(customerId)
                        .subscribe((locations: any[]) => {
                            const sortedLocations = orderBy(locations, [ v => v.name ]);
                            const project = {id: menuItem.dataId};
                            menuItem.submenu = locations && locations.length > 0 ? this.prepareLocationsSubmenu(project, sortedLocations, menuItem.parent.id) : [];
                        });
                });
                customerLocationSubscription.unsubscribe();
            } else {
                const project = {id: menuItem.dataId};
                this.projectService.getLocationsByEvaluations(menuItem.dataId)
                    .subscribe(locations => {
                        menuItem.submenu = locations && locations.length > 0 ? this.prepareLocationsSubmenu(project, locations, menuItem.parent.id) : [];
                    });
            }
        }
    }
    
    private setHomeLink(staticItems: any[]) {
        return this.routerInfoService.getRouteByUserRole()
            .subscribe(url => {
                staticItems[0]['submenu'][0].path = url;
                return of(staticItems);
            });
    }
    
    private prepareCustomerSubmenu(customers, projects, parentItem) {
        
        return customers.map((customer) => {
            const projectsSubmenu = this.prepareProjectsSubmenu(customer, projects, parentItem);
            if (projectsSubmenu.length > 0) {
                return {
                    path: '',
                    title: customer.name,
                    icon: '',
                    matIcon: '',
                    class: 'sub',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: projectsSubmenu
                }
            }
            
            return null;
        });
    }
    
    private prepareProjectsSubmenu(customer, projects, parentItem) {
        const customerId = customer.id;
        const projectsForCurrentCustomer = projects.filter(project => project.customer.id == customerId && project.active);
        return projectsForCurrentCustomer.map((project) => {
            return { path: '', title: project.name, icon: '', matIcon: '', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [], dataId: project.id, parent: parentItem };
        });
    }
    
    private prepareLocationsSubmenu(project, locations, menuType) {
        return locations.map((location) => {
            let path = `/evaluation/location/${project.id}/${location.id}`;
            if (menuType == 'reportingMenuItem') {
                path = `/reporting/location/${project.id}/${location.id}`;
            }
            if (menuType == 'achievementMenuItem') {
                path = `/achievements/location/${project.id}/${location.id}`;
            }
            return { path: path, title: location.name, icon: '', matIcon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
        })
    }
    
    
}
