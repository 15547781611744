import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CustomerService } from "../../services/customer.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { CustomersAppState } from "./customer.reducer";
import {
    loadCustomers,
    loadCustomersSuccess,
    invokeUpdateCustomer,
    updateCustomerSuccess,
    invokeAddCustomer,
    addCustomerSuccess, invokeDeleteCustomer, deleteCustomerSuccess
} from "./customer.actions";
import { selectAllCustomers } from "./customer.selectors";

@Injectable()
export class CustomerEffects {

    constructor(
        private customerService: CustomerService,
        private actions$: Actions,
        private store: Store<CustomersAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    customersLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadCustomers),
            withLatestFrom(this.store.pipe(select(selectAllCustomers))),
            mergeMap(([,customersFromStore]: any) => {
                if (customersFromStore.length > 0) {
                    return EMPTY;
                }
                return this.customerService.getAll()
                    .pipe(map((data) => loadCustomersSuccess({customers: data})));
            })
        )
    );

    updateCustomer$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateCustomer),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.customerService.update(action.updateCustomer).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateCustomerSuccess({ updateCustomer: data });
                    })
                );
            })
        );
    });

    addCustomer$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddCustomer),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.customerService.add(action.addCustomer).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addCustomerSuccess({ addCustomer: data });
                    })
                );
            })
        );
    });

    deleteCustomer$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteCustomer),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.customerService.delete(action.deleteCustomerId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteCustomerSuccess({ deleteCustomerId: action.deleteCustomerId });
                    })
                );
            })
        );
    });
}
