import { RouteInfo } from './sidebar.metadata';
import { AuthGuard } from "../../modules/auth/auth-guard.service";
import { Role } from "../../models/role";

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    {
        path: '', title: 'Dashboard', icon: 'bx bx-home-circle', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/dashboard/start', title: 'translations.HOME', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/dashboard/e-commerce', title: 'translations.HOME', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/dashboard/analytics', title: 'Analytics', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/reporting/selection', title: 'Einstieg', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'translations.MAIN_NAVI_ADMIN', icon: 'bx bx-slider-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.MasterGuide] }, submenu: [
          { path: '/admin/customers', title: 'translations.NAVI_CUSTOMERS', icon: '', matIcon: 'business', class: '', badge: '', badgeClass: '', isExternalLink: false, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin]}, submenu: [] },
          { path: '/admin/customer-profile', title: 'translations.NAVI_CUSTOMERS_PROFILE', icon: '', matIcon: 'business', class: '', badge: '', badgeClass: '', isExternalLink: false, canActivate: [AuthGuard], data: { roles: [Role.CustomerAdmin, Role.MasterGuide]}, submenu: [] },
          { path: '/admin/projects', title: 'translations.NAVI_PROJECTS', icon: '', matIcon: 'work', class: '', badge: '', badgeClass: '', isExternalLink: false, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin]}, submenu: [] },
          { path: '/admin/locations', title: 'translations.NAVI_LOCATIONS', icon: '', matIcon: 'store_mall_directory', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '', title: 'translations.NAVI_MATRIXES', icon: '', matIcon: 'view_module', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin]}, submenu: [
                  { path: '/admin/matrices', title: 'translations.NAVI_MATRIXES', icon:'', matIcon: 'view_module', class: 'no-locations-loading', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                  { path: '/admin/attributes', title: 'translations.NAVI_ATTRIBUTES', icon: '', matIcon: 'business', class: 'no-locations-loading', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                  { path: '/admin/attribute-sets', title: 'translations.NAVI_ATTRIBUTESETS', icon: '', matIcon: '', class: 'no-locations-loading', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
          ] },
          { path: '/admin/users', title: 'translations.NAVI_USERS', icon: '', matIcon: 'people',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/admin/specialists', title: 'translations.NAVI_EMPLOYEE', icon: '', matIcon: 'rowing', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/admin/regions', title: 'translations.NAVI_REGIONS', icon: '', matIcon: 'explore', class: '', badge: '', badgeClass: '', isExternalLink: false, canActivate: [AuthGuard], data: { roles: [Role.SuperAdmin]}, submenu: [] },
      ]
    },
    {
        path: '', id: 'evaluationMenuItem', title: 'translations.MAIN_NAVI_EVALUTATION', icon: 'bx bx-fast-forward-circle', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,  data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.MasterGuide] }, submenu: []
    },
    {
        path: '', id: 'reportingMenuItem', title: 'translations.MAIN_NAVI_REPORTS', icon: 'bx bx-line-chart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,  data: { roles: [Role.SuperAdmin, Role.CustomerAdmin, Role.MasterGuide] }, submenu: []
    },
    {
        path: '', id: 'achievementMenuItem', title: 'translations.MAIN_NAVI_ACHIEVMENTS', icon: '', matIcon: 'handshake', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,  data: { roles: [Role.SuperAdmin, Role.CustomerAdmin] }, submenu: []
    },


];
