import { createAction, props } from "@ngrx/store";
import { Project } from "../../models/project";

export const loadProjects = createAction(
    '[Project] Load all Projects'
);

export const loadProjectsSuccess = createAction(
    '[Project] Load all Projects Success',
    props<{ projects: Project[] }>()
);

export const invokeUpdateProject = createAction(
    '[Project] Update a Project',
    props<{updateProject: Project}>()
);

export const updateProjectSuccess = createAction(
    '[Project] Update a Project Success',
    props<{updateProject: Project}>()
);

export const invokeAddProject = createAction(
    '[Project] Add a Project',
    props<{addProject: Project}>()
);

export const addProjectSuccess = createAction(
    '[Project] Add a Project Success',
    props<{addProject: Project}>()
);

export const invokeDeleteProject = createAction(
    '[Project] Delete a Project',
    props<{deleteProjectId: number}>()
);

export const deleteProjectSuccess = createAction(
    '[Project] Delete a Project Success',
    props<{deleteProjectId: number}>()
);


export const loadProjectsError = createAction(
    '[Project] Load Projects Error',
    props<{ error: any }>()
);

export const updateProjectsError = createAction(
    '[Project] Update Projects Error',
    props<{ error: any }>()
);

export const loadProjectsShort = createAction(
    '[ProjectShort] Load all Projects (short)'
);

export const loadProjectsShortSuccess = createAction(
    '[ProjectShort] Load all Projects (short) Success',
    props<{ projects: Project[] }>()
);

export const invokeUpdateProjectShort = createAction(
    '[ProjectShort] Update a Project (short)',
    props<{updateProjectShort: Project}>()
);

export const updateProjectShortSuccess = createAction(
    '[ProjectShort] Update a Project (short) Success',
    props<{updateProjectShort: Project}>()
);

export const invokeAddProjectShort = createAction(
    '[ProjectShort] Add a Project',
    props<{addProjectShort: Project}>()
);

export const addProjectShortSuccess = createAction(
    '[ProjectShort] Add a Project Success',
    props<{addProjectShort: Project}>()
);

export const invokeDeleteProjectShort = createAction(
    '[ProjectShort] Delete a Project',
    props<{deleteProjectShortId: number}>()
);

export const deleteProjectShortSuccess = createAction(
    '[ProjectShort] Delete a Project Success',
    props<{deleteProjectShortId: number}>()
);


export const loadProjectsShortError = createAction(
    '[ProjectShort] Load Projects Error',
    props<{ error: any }>()
);

export const updateProjectsShortError = createAction(
    '[ProjectShort] Update Projects Error',
    props<{ error: any }>()
);



