import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromUser from "./user.reducer";
import { UserState } from "./user.reducer";

export const selectUserState = createFeatureSelector<fromUser.UserState>(
    fromUser.usersFeatureKey
);

export const selectAllUsers = createSelector(
    selectUserState,
    ls => ls.users
)

export const selectUsers = createFeatureSelector<UserState>('Users');

export const selectUserById = (userId: number) => createSelector(
    selectUsers, (userState: UserState) => {
        let userById = userState.users.filter((_) => _.id == userId);
        if (userById.length == 0) {
            return null;
        }
        return userById[0];
    });
