import { createAction, props } from "@ngrx/store";
import { Matrix } from "../../models/matrix";

export const loadMatrices = createAction(
    '[Matrix] Load all Matrices'
);

export const loadMatricesSuccess = createAction(
    '[Matrix] Load all Matrices Success',
    props<{ matrices: Matrix[] }>()
);

export const loadSingleMatrix = createAction(
    '[Matrix] Load a single Matrix'
);

export const loadSingleMatrixSucess = createAction(
    '[Matrix] Load a single Matrix Success',
    props<{ matrix: Matrix }>()
);

export const invokeUpdateMatrix = createAction(
    '[Matrix] Update a Matrix',
    props<{updateMatrix: Matrix}>()
);

export const updateMatrixSuccess = createAction(
    '[Matrix] Update a Matrix Success',
    props<{updateMatrix: Matrix}>()
);

export const invokeAddMatrix = createAction(
    '[Matrix] Add a Matrix',
    props<{addMatrix: Matrix}>()
);

export const addMatrixSuccess = createAction(
    '[Matrix] Add a Matrix Success',
    props<{addMatrix: Matrix}>()
);

export const invokeDeleteMatrix = createAction(
    '[Matrix] Delete a Matrix',
    props<{deleteMatrixId: number}>()
);

export const deleteMatrixSuccess = createAction(
    '[Matrix] Delete a Matrix Success',
    props<{deleteMatrixId: number}>()
);


export const loadMatricesError = createAction(
    '[Matrix] Load Matrices Error',
    props<{ error: any }>()
);

export const updateMatricesError = createAction(
    '[Matrix] Update Matrices Error',
    props<{ error: any }>()
);

export const loadMatricesShort = createAction(
    '[MatrixShort] Load all Matrices (short)'
);

export const loadMatricesShortSuccess = createAction(
    '[MatrixShort] Load all Matrices (short) Success',
    props<{ matrices: Matrix[] }>()
);

export const invokeUpdateMatrixShort = createAction(
    '[MatrixShort] Update a Matrix (short)',
    props<{updateMatrixShort: Matrix}>()
);

export const updateMatrixShortSuccess = createAction(
    '[MatrixShort] Update a Matrix (short) Success',
    props<{updateMatrixShort: Matrix}>()
);

export const invokeAddMatrixShort = createAction(
    '[MatrixShort] Add a Matrix',
    props<{addMatrixShort: Matrix}>()
);

export const addMatrixShortSuccess = createAction(
    '[MatrixShort] Add a Matrix Success',
    props<{addMatrixShort: Matrix}>()
);

export const invokeDeleteMatrixShort = createAction(
    '[MatrixShort] Delete a Matrix',
    props<{deleteMatrixShortId: number}>()
);

export const deleteMatrixShortSuccess = createAction(
    '[MatrixShort] Delete a Matrix Success',
    props<{deleteMatrixShortId: number}>()
);


export const loadMatricesShortError = createAction(
    '[MatrixShort] Load Matrices Error',
    props<{ error: any }>()
);

export const updateMatricesShortError = createAction(
    '[MatrixShort] Update Matrices Error',
    props<{ error: any }>()
);



