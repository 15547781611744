import { Component , OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { AuthService } from "../../modules/auth/auth.service";
import { User } from "../../models/user";
import { RouterInfoService } from "../../services/router-info.service";
import $ from 'jquery';
import { TranslateService } from "@ngx-translate/core";
import { config } from "../../app.config";
import { BasicService } from "../../services/basic.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../../environments/environment';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    
    fullname: string;
    role: string;
    image: string;
    
    startPageUrl: string;
    languages: string[] = config.languages;
    currentLang: string;
    
    envName: string = '';

    constructor(
        public sidebarService: SidebarService,
        public basicService: BasicService,
        private authService: AuthService,
        private routerInfoService: RouterInfoService,
        private translate: TranslateService,
        private route: ActivatedRoute
    ) {}
    
    ngOnInit() {
        this.envName = environment.name;
        
        const user: User = JSON.parse(localStorage.getItem('user'));
        this.fullname = user.fullname;
        this.role = user.roles[0];
        this.image = user.image;
        
        this.currentLang = localStorage.getItem('lang');
        
        /* Search Bar */
        $(document).ready(function () {
            $(".mobile-search-icon").on("click", function () {
                $(".search-bar").addClass("full-search-bar")
            }),
                $(".search-close").on("click", function () {
                    $(".search-bar").removeClass("full-search-bar")
                })
        });
        
        this.routerInfoService.getRouteByUserRole()
            .subscribe(url => this.startPageUrl = '/' + url);
        
    }

    toggleSidebar() {
        this.sidebarService.setSidebarState(!this.sidebarService.getSidebarState());
    }

    getSideBarState() {
        return this.sidebarService.getSidebarState();
    }

    hideSidebar() {
        this.sidebarService.setSidebarState(true);
    }

    onLogout() {
        this.authService.logout();
    }
    
    changeLanguage(lang: string) {
        this.translate.use(lang);
        localStorage.setItem('lang', lang);
        this.currentLang = lang;
        this.basicService.reloadComponent(this.route.snapshot['_routerState'].url);
    }
}
