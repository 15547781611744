import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Observable } from "rxjs";
import { Employee } from "../models/employee";

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    
    resourceType: string = 'employees';
    resourceTypeShort: string = 'employees/short';
    resource$ = null;
    resourceShort$ = null;
    
    constructor(
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
        this.resourceShort$ = this.restangular.all(this.resourceTypeShort);
    }
    
    getAllShort(): Observable<any> {
        
        return this.resourceShort$.getList();
    }
    
    addShort(payload: Employee) {
        return this.resourceShort$.post(payload);
    }
    
    updateShort(payload: Employee) {
        return this.restangular.one(this.resourceTypeShort, payload.id).customPUT(payload);
    }
    
    deleteShort(id: number): Observable<any> {
        return this.restangular.one(this.resourceTypeShort, id).remove(id);
    }
    
    getAll(): Observable<any> {
        return this.resource$.getList();
    }
    
    add(payload: Employee) {
        return this.resource$.post(payload);
    }
    
    update(payload: Employee) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }
    
    delete(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).remove(id);
    }
    
    getById(id: number): Observable<any> {
        return this.resource$.get(id);
    }
    
    getAchievements(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).customGETLIST('achievements');
    }
    
    getPlannedEvaluations(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).customGETLIST('planned-evaluations');
    }
}
