import { createAction, props } from "@ngrx/store";
import { AttributeSet } from "../../models/attribute-set";

export const loadAttributeSets = createAction(
    '[AttributeSet] Load all AttributeSets'
);

export const loadAttributeSetsSuccess = createAction(
    '[AttributeSet] Load all AttributeSets Success',
    props<{ attributeSets: AttributeSet[] }>()
);

export const loadAttributeSetsError = createAction(
    '[AttributeSet] Load AttributeSets Error',
    props<{ error: any }>()
);

export const invokeUpdateAttributeSet = createAction(
    '[AttributeSet] Update a AttributeSet',
    props<{updateAttributeSet: AttributeSet}>()
);

export const updateAttributeSetSuccess = createAction(
    '[AttributeSet] Update a AttributeSet Success',
    props<{updateAttributeSet: AttributeSet}>()
);

export const invokeAddAttributeSet = createAction(
    '[AttributeSet] Add a AttributeSet',
    props<{addAttributeSet: AttributeSet}>()
);

export const addAttributeSetSuccess = createAction(
    '[AttributeSet] Add a AttributeSet Success',
    props<{addAttributeSet: AttributeSet}>()
);

export const invokeDeleteAttributeSet = createAction(
    '[AttributeSet] Delete a AttributeSet',
    props<{deleteAttributeSetId: number}>()
);

export const deleteAttributeSetSuccess = createAction(
    '[AttributeSet] Delete a AttributeSet Success',
    props<{deleteAttributeSetId: number}>()
);

export const updateAttributeSetsError = createAction(
    '[AttributeSet] Update AttributeSets Error',
    props<{ error: any }>()
);
