import {Component, TemplateRef} from '@angular/core';

import {ToastService} from '../../services/toast.service';


@Component({
    selector: 'app-toasts',
    template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

        <ng-template #text><span [innerHtml]="toast.textOrTpl|nl2br"></span></ng-template>
    </ngb-toast>
  `,
    host: {'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 1200'}
})
export class ToastsContainer {
    constructor(public toastService: ToastService) {}
    
    isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}