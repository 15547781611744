import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordRepeatValidator } from '../../../services/validators/passwordRepeat.validator';
import { config } from '../../../app.config';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../auth.service';
import { RouterInfoService } from '../../../services/router-info.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-renew-password',
    templateUrl: './renew-password.component.html',
    styleUrls: [ './renew-password.component.scss' ]
})
export class RenewPasswordComponent implements OnInit {
    
    form: FormGroup;
    passwordMinLength: number = config.password.minLength;
    responseMessage: string = '';
    // responseError: boolean = false;
    submitted: boolean = false;
    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private fb: FormBuilder,
        private authService: AuthService,
        private routerInfoService: RouterInfoService,
        private userService: UserService
    ) {
        const lang = translate.getBrowserLang();
        localStorage.setItem('lang', lang);
        translate.use(lang);
        
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', [
                Validators.required,
                Validators.minLength(this.passwordMinLength),
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&§()=\\[\\]{}_\\-#:;\\+~<>])[A-Za-z\\d@$!%*?&§()=\\[\\]{}_\\-#:;\\+~<>]{10,}$')
            ]],
            password_repeat: ['', Validators.required]
        }, {
            validators: PasswordRepeatValidator.checkPasswordsAreTheSame
        });
    }
    
    ngOnInit() {
        const username = localStorage.getItem('renewalUsername');
        this.form.patchValue({'username': username});
        localStorage.removeItem('renewalUsername');
    }
    
    get formControl() {
        return this.form.controls;
    }
    
    onSend() {
        this.submitted = true;
        if (this.form.valid) {
            const val = this.form.value;
            
            this.userService.renewPassword({password: val.password, username: val.username})
                .subscribe({
                    next: message => {
                        this.authService.login(val.username, val.password)
                            .subscribe(() => {
                                    this.routerInfoService.getRouteByUserRole()
                                        .subscribe(url =>  this.router.navigateByUrl(url))
                                });
                    },
                    error: err => {
                        this.responseMessage = err.data['hydra:description'];
                    }
                });
        }
    }
}
