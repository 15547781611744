import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LocationService } from "../../services/location.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { LocationsAppState } from "./location.reducer";
import {
    loadLocations,
    loadLocationsSuccess,
    invokeUpdateLocation,
    updateLocationSuccess,
    invokeAddLocation,
    addLocationSuccess, invokeDeleteLocation, deleteLocationSuccess
} from "./location.actions";
import { selectAllLocations } from "./location.selectors";

@Injectable()
export class LocationEffects {

    constructor(
        private locationService: LocationService,
        private actions$: Actions,
        private store: Store<LocationsAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    locationsLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadLocations),
            withLatestFrom(this.store.pipe(select(selectAllLocations))),
            mergeMap(([,locationsFromStore]: any) => {
                if (locationsFromStore.length > 0) {
                    return EMPTY;
                }
                return this.locationService.getAll()
                    .pipe(map((data) => loadLocationsSuccess({locations: data})));
            })
        )
    );

    updateLocation$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateLocation),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.locationService.update(action.updateLocation).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateLocationSuccess({ updateLocation: data });
                    })
                );
            })
        );
    });

    addLocation$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddLocation),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.locationService.add(action.addLocation).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addLocationSuccess({ addLocation: data });
                    })
                );
            })
        );
    });

    deleteLocation$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteLocation),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.locationService.delete(action.deleteLocationId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteLocationSuccess({ deleteLocationId: action.deleteLocationId });
                    })
                );
            })
        );
    });
}
