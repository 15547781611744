import { createAction, props } from "@ngrx/store";
import { Language } from "../../models/language";

export const loadLanguages = createAction(
    '[Language] Load all Languages'
);

export const loadLanguagesSuccess = createAction(
    '[Language] Load all Languages Success',
    props<{ languages: Language[] }>()
);

export const loadLanguagesError = createAction(
    '[Language] Load Languages Error',
    props<{ error: any }>()
);
