import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AttributeSetService } from "../../services/attribute-set.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { AttributeSetsAppState } from "./attribute-set.reducer";
import {
    loadAttributeSets,
    loadAttributeSetsSuccess,
    invokeUpdateAttributeSet,
    updateAttributeSetSuccess,
    invokeAddAttributeSet,
    addAttributeSetSuccess, invokeDeleteAttributeSet, deleteAttributeSetSuccess
} from "./attribute-set.actions";
import { selectAllAttributeSets } from "./attribute-set.selectors";
import { AttributeSet } from "../../models/attribute-set";

@Injectable()
export class AttributeSetEffects {

    constructor(
        private attributeSetService: AttributeSetService,
        private actions$: Actions,
        private store: Store<AttributeSetsAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    attributeSetsLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadAttributeSets),
            withLatestFrom(this.store.pipe(select(selectAllAttributeSets))),
            mergeMap(([ , attributeSetsFromStore ]: any) => {
                if (attributeSetsFromStore.length > 0) {
                    return EMPTY;
                }
                return this.attributeSetService.getAll()
                    .pipe(map((data: AttributeSet[]) => loadAttributeSetsSuccess({attributeSets: data})));
            })
        )
    );

    updateAttributeSet$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateAttributeSet),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.attributeSetService.update(action.updateAttributeSet).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateAttributeSetSuccess({ updateAttributeSet: data });
                    })
                );
            })
        );
    });

    addAttributeSet$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddAttributeSet),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.attributeSetService.add(action.addAttributeSet).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addAttributeSetSuccess({ addAttributeSet: data });
                    })
                );
            })
        );
    });

    deleteAttributeSet$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteAttributeSet),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.attributeSetService.delete(action.deleteAttributeSetId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteAttributeSetSuccess({ deleteAttributeSetId: action.deleteAttributeSetId });
                    })
                );
            })
        );
    });
}
