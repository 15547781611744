import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RouterInfoService } from '../../../services/router-info.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ]
})
export class LoginComponent {
    form: FormGroup;
    loading: boolean = false;
    error: any = null;
    envName: string = '';
    // currentLang: string = 'de';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private routerInfoService: RouterInfoService,
        private fb: FormBuilder,
        private jwtHelper: JwtHelperService,
        private translate: TranslateService
    ) {
        const lang =  translate.getBrowserLang();
        localStorage.setItem('lang', lang);
        translate.use(lang);
        
        this.envName = environment.name;

        this.form = this.fb.group({
            username: [ '', Validators.required ],
            password: [ '', Validators.required ]
        });
    }

    // On Forgotpassword link click
    onForgotpassword() {
        this.router.navigate([ 'forgot-password' ], {relativeTo: this.route.parent});
    }

    // On Signup link click
    onSignup() {
        this.router.navigate([ 'sign-up' ], {relativeTo: this.route.parent});
    }

    onLogin() {
        this.authService.removeSession();
        const val = this.form.value;

        if (val.username && val.password) {
            this.authService.login(val.username, val.password)
                .subscribe({
                        next: () => {
                            // get return url from query parameters or default to home page
                            this.routerInfoService.getRouteByUserRole()
                                .subscribe(url => {
                                    localStorage.setItem('wrongPasswordCount', '0');
                                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || url;
                                    this.router.navigateByUrl(returnUrl);
                                });
                            
                        },
                        error: response => {
                            console.log(response);
                            if (response.error.message == 'PASSWORD_OR_USERNAME_ERROR') {
                                this.authService.checkWrongPasswordCount(val.username)
                                    .subscribe(checkResponse => {
                                        if (checkResponse.hasOwnProperty('message')) {
                                            this.error = checkResponse;
                                        } else {
                                            this.error = response.error;
                                        }
                                        this.loading = false;
                                    });
                            } else if (response.error.message == 'PASSWORD_EXPIRED') {
                                localStorage.setItem('renewalUsername', this.form.value.username);
                                this.router.navigate([ 'renew-password' ], {relativeTo: this.route.parent});
                            } else if (response.error.message == 'Expired JWT Token') {
                                this.error = 'JWT_TOKEN_EXPIRED';
                            } else {
                                this.error = response.error;
                            }
                        }
                    }
                );
        }
    }
    
    togglePasswordVisibility($event: Event) {
        const passwdInputField = document.getElementById('inputChoosePassword');
        const toggleButton = $event.target as HTMLInputElement;
        
        const type = passwdInputField.getAttribute('type') === 'password' ? 'text' : 'password';
        passwdInputField.setAttribute('type', type);
        
        toggleButton.classList.toggle('bx-show');
    }
}
