import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ProjectService } from "../../services/project.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { ProjectsAppState } from "./project.reducer";
import {
    loadProjects,
    loadProjectsSuccess,
    invokeUpdateProject,
    updateProjectSuccess,
    invokeAddProject,
    addProjectSuccess,
    invokeDeleteProject,
    deleteProjectSuccess,
    loadProjectsShort,
    loadProjectsShortSuccess,
    invokeUpdateProjectShort,
    updateProjectShortSuccess,
    invokeAddProjectShort,
    invokeDeleteProjectShort,
    deleteProjectShortSuccess, addProjectShortSuccess
} from "./project.actions";
import { selectAllProjects, selectAllProjectsShort } from "./project.selectors";
import { Project } from "../../models/project";

@Injectable()
export class ProjectEffects {

    constructor(
        private projectService: ProjectService,
        private actions$: Actions,
        private store: Store<ProjectsAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    projectsLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadProjects),
            withLatestFrom(this.store.pipe(select(selectAllProjects))),
            mergeMap(([ , projectsFromStore ]: any) => {
                if (projectsFromStore.length > 0) {
                    return EMPTY;
                }
                return this.projectService.getAll()
                    .pipe(map((data: Project[]) => loadProjectsSuccess({projects: data})));
            })
        )
    );
    
    projectsLoadShort$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadProjectsShort),
            withLatestFrom(this.store.pipe(select(selectAllProjectsShort))),
            mergeMap(([ , projectsFromStore ]: any) => {
                if (projectsFromStore.length > 0) {
                    return EMPTY;
                }
                return this.projectService.getAllShort()
                    .pipe(map((data: Project[]) => loadProjectsShortSuccess({projects: data})));
            })
        )
    );

    updateProject$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateProject),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.projectService.update(action.updateProject).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateProjectSuccess({ updateProject: data });
                    })
                );
            })
        );
    });
    
    updateProjectShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateProjectShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.projectService.updateShort(action.updateProjectShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateProjectShortSuccess({ updateProjectShort: data });
                    })
                );
            })
        );
    });

    addProject$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddProject),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.projectService.add(action.addProject).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addProjectSuccess({ addProject: data });
                    })
                );
            })
        );
    });
    
    addProjectShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddProjectShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.projectService.addShort(action.addProjectShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addProjectShortSuccess({ addProjectShort: data });
                    })
                );
            })
        );
    });

    deleteProject$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteProject),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.projectService.delete(action.deleteProjectId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteProjectSuccess({ deleteProjectId: action.deleteProjectId });
                    })
                );
            })
        );
    });
    
    deleteProjectShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteProjectShort),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.projectService.deleteShort(action.deleteProjectShortId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteProjectShortSuccess({ deleteProjectShortId: action.deleteProjectShortId });
                    })
                );
            })
        );
    });
}
