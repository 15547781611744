import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegacyComponent } from './legacy.component';
import { BrowserModule } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";



@NgModule({
  declarations: [
    LegacyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterOutlet
  ],
  exports: [
    LegacyComponent
  ]
})
export class LegacyModule { }
