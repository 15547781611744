import { FormGroup, ValidationErrors } from "@angular/forms";

export class PasswordRepeatValidator {
    
    static checkPasswordsAreTheSame(formGroup: FormGroup): ValidationErrors | null {
        const password = formGroup.get('password')?.value;
        const passwordRepeat = formGroup.get('password_repeat')?.value;
        
        if (password !== passwordRepeat) {
            return {
                notTheSame: true
            };
        }
        return null;
    }
}