import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterModule,
  Routes,
  PreloadAllModules,
  ExtraOptions,
  TitleStrategy,
  RouterStateSnapshot
} from '@angular/router';


import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from "./modules/auth/auth-guard.service";

import { Role } from "./models/role";
import { TranslateService } from "@ngx-translate/core";

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  { path: '**', redirectTo: 'login' }
];

// @Injectable()
// export class TemplatePageTitleStrategy extends TitleStrategy {
//   constructor(
//       private readonly title: Title,
//   ) {
//     super();
//   }
//
//   override updateTitle(routerState: RouterStateSnapshot, translate: TranslateService) {
//     const title = this.buildTitle(routerState);
//     if (title !== undefined) {
//       if (title.indexOf('translations') !== -1) {
//         translate.get(title).subscribe((term) => {
//           this.title.setTitle(`${term} - Syme eXctra 3.0`);
//         });
//       } else {
//         this.title.setTitle(`${title} - Syme eXctra 3.0`);
//       }
//     }
//   }
// }


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  // providers: [
  //   {
  //     provide: TitleStrategy,
  //     useClass: TemplatePageTitleStrategy
  //   }
  // ]
})
export class AppRoutingModule { }
