<!--start header -->
<header>
	<div class="topbar d-flex align-items-center">
		<nav class="navbar navbar-expand">
			<div class="mobile-toggle-menu"><i (click)="toggleSidebar()" class='bx bx-menu'></i>
			</div>
			<div class="top-menu ms-auto">
				<ul class="navbar-nav align-items-center">
					<li class="nav-item mobile-search-icon">
						<a class="nav-link" href="#" (click)="$event.preventDefault()">	<i class='bx bx-search'></i>
						</a>
					</li>
                    <li class="nav-item" *ngIf="envName != 'prod'">
                        <span class="alert alert-warning" role="alert">{{envName}}</span>
                    </li>
				</ul>
			</div>
			<div class="user-box dropdown">
				<a id="menuToggler" class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret ms-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<img src="assets/images/avatars/profile.png" class="user-img" alt="user avatar" *ngIf="!image">
					<img onerror="this.src='assets/images/avatars/profile.png'" src="{{image}}" class="user-img" alt="user avatar" *ngIf="image">
					<div class="user-info ps-3">
						<p class="user-name mb-0">{{fullname}}</p>
						<p class="designattion mb-0">{{'translations.'+role|translate}}</p>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="menuToggler">
					<li><a class="dropdown-item" [routerLink]="[startPageUrl]"><i class="bx bx-home-circle"></i><span>{{'translations.HOME'|translate}}</span></a>
					<li><a class="dropdown-item" routerLink="/settings"><i class="bx bx-user"></i><span>{{'translations.SETTINGS'|translate}}</span></a>
                    <ng-container *ngFor="let language of languages">
                        <li *ngIf="language != currentLang">
                            <a class="dropdown-item cursor-pointer" (click)="changeLanguage(language)">
                                <i class="bx bx-globe"></i>
                                <span>{{'translations.LANG_' + basicService.strToUpperCase(language)|translate}}</span>
                            </a>
                        </li>
                    </ng-container>
					<li>
            			<a class="dropdown-item link-item" (click)="onLogout()"><i class='bx bx-log-out-circle'></i><span>Logout</span></a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</header>
<!--end header -->
