import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  
  resource: string = 'countries';

  constructor(
      private restangular: Restangular
  ) { }
  
  getAll(): Observable<any> {
    return this.restangular.all(this.resource).getList();
  }
}
