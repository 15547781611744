import { createReducer, on } from "@ngrx/store";
import * as LanguageActions from "./language.actions";
import { Language } from "../../models/language";

export const languagesFeatureKey = 'Languages';

export interface LanguagesAppState {
    [languagesFeatureKey]: LanguageState;
}

export interface LanguageState {
    languages: Language[];
    selectedLanguage: Language|null;
    isLoading: boolean;
}

export const initialLanguageState: LanguageState =  {
    languages: [],
    selectedLanguage: null,
    isLoading: false
}


export const LanguageReducer = createReducer(
    initialLanguageState,
    on(LanguageActions.loadLanguages, (state): LanguageState => {
        const languages = [] as Language[];
        const isLoading = true;
        return {...state, languages, isLoading};
    }),
    on(LanguageActions.loadLanguagesSuccess, (state, action): LanguageState => {
        const languages = action.languages;
        const isLoading = false;
        return {...state, languages, isLoading};
    })
);
