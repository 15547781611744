import { Routes } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    // {
    //     path: 'error',
    //     loadChildren: () => import('./../../error/error.module').then(m => m.ErrorModule)
    // },
    {
        path: '',
        loadChildren: () => import('../../modules/auth/auth.module').then(m => m.AuthModule)
    }
];
