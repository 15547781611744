import { createReducer, on } from "@ngrx/store";
import * as LocationActions from "./location.actions";
import { Location } from "../../models/location";

export const locationsFeatureKey = 'Locations';

export interface LocationsAppState {
    [locationsFeatureKey]: LocationState;
}

export interface LocationState {
    locations: Location[];
    selectedLocation: Location|null;
    isLoading: boolean;
}

export const initialLocationState: LocationState =  {
    locations: [],
    selectedLocation: null,
    isLoading: false
}


export const LocationReducer = createReducer(
    initialLocationState,
    on(LocationActions.loadLocations, (state): LocationState => {
        const locations = [] as Location[];
        const isLoading = true;
        return {...state, locations, isLoading};
    }),
    on(LocationActions.loadLocationsSuccess, (state, action): LocationState => {
        const locations = action.locations;
        const isLoading = false;
        return {...state, locations, isLoading};
    }),
    on(LocationActions.updateLocationSuccess, (state: LocationState, { updateLocation }): LocationState => {
        let locations = state.locations.filter((_) => _.id != updateLocation.id);
        locations.unshift(updateLocation);
        const isLoading = false;
        return {...state, locations, isLoading};
    }),
    on(LocationActions.addLocationSuccess, (state: LocationState, { addLocation }): LocationState => {
        let locations = [...state.locations];
        locations.unshift(addLocation);
        const isLoading = false;
        return {...state, locations, isLoading};
    }),
    on(LocationActions.deleteLocationSuccess, (state: LocationState, { deleteLocationId }): LocationState => {
        let locations = state.locations.filter((_) => _.id != deleteLocationId);
        // locations.shift(deleteLocation);
        const isLoading = false;
        return {...state, locations, isLoading};
    })
);
