import { createReducer, on } from "@ngrx/store";
import * as MatrixActions from "./matrix.actions";
import { Matrix } from "../../models/matrix";

export const matricesFeatureKey = 'Matrices';
export const matricesShortFeatureKey = 'MatricesShort';

export interface MatricesAppState {
    [matricesFeatureKey]: MatrixState;
}

export interface MatricesShortAppState {
    [matricesShortFeatureKey]: MatrixShortState;
}

export interface MatrixState {
    matrices: Matrix[];
    selectedMatrix: Matrix|null;
    isLoading: boolean;
}

export const initialMatrixState: MatrixState =  {
    matrices: [],
    selectedMatrix: null,
    isLoading: false
}

export interface MatrixShortState {
    matrices: Matrix[];
    selectedMatrix: Matrix|null;
    isLoading: boolean;
}

export const initialMatrixShortState: MatrixShortState =  {
    matrices: [],
    selectedMatrix: null,
    isLoading: false
}


export const MatrixShortReducer = createReducer(
    initialMatrixShortState,
    on(MatrixActions.loadMatricesShort, (state): MatrixShortState => {
        const matrices = [] as Matrix[];
        const isLoading = true;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.loadMatricesShortSuccess, (state, action): MatrixShortState => {
        const matrices = action.matrices;
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.updateMatrixShortSuccess, (state: MatrixShortState, { updateMatrixShort }): MatrixShortState => {
        let matrices = state.matrices.filter((_) => _.id != updateMatrixShort.id);
        matrices.unshift(updateMatrixShort);
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.addMatrixShortSuccess, (state: MatrixShortState, { addMatrixShort }): MatrixShortState => {
        let matrices = [...state.matrices];
        matrices.unshift(addMatrixShort);
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.deleteMatrixShortSuccess, (state: MatrixShortState, { deleteMatrixShortId }): MatrixShortState => {
        let matrices = state.matrices.filter((_) => _.id != deleteMatrixShortId);
        const isLoading = false;
        return {...state, matrices, isLoading};
    })
);

export const MatrixReducer = createReducer(
    initialMatrixState,
    on(MatrixActions.loadMatrices, (state): MatrixState => {
        const matrices = [] as Matrix[];
        const isLoading = true;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.loadMatricesSuccess, (state, action): MatrixState => {
        const matrices = action.matrices;
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.updateMatrixSuccess, (state: MatrixState, { updateMatrix }): MatrixState => {
        let matrices = state.matrices.filter((_) => _.id != updateMatrix.id);
        matrices.unshift(updateMatrix);
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.addMatrixSuccess, (state: MatrixState, { addMatrix }): MatrixState => {
        let matrices = [...state.matrices];
        matrices.unshift(addMatrix);
        const isLoading = false;
        return {...state, matrices, isLoading};
    }),
    on(MatrixActions.deleteMatrixSuccess, (state: MatrixState, { deleteMatrixId }): MatrixState => {
        let matrices = state.matrices.filter((_) => _.id != deleteMatrixId);
        const isLoading = false;
        return {...state, matrices, isLoading};
    })
);
