import { createAction, props } from "@ngrx/store";
import { Location } from "../../models/location";

export const loadLocations = createAction(
    '[Location] Load all Locations'
);

export const loadLocationsSuccess = createAction(
    '[Location] Load all Locations Success',
    props<{ locations: Location[] }>()
);

export const loadLocationsError = createAction(
    '[Location] Load Locations Error',
    props<{ error: any }>()
);

export const invokeUpdateLocation = createAction(
    '[Location] Update a Location',
    props<{updateLocation: Location}>()
);

export const updateLocationSuccess = createAction(
    '[Location] Update a Location Success',
    props<{updateLocation: Location}>()
);

export const invokeAddLocation = createAction(
    '[Location] Add a Location',
    props<{addLocation: Location}>()
);

export const addLocationSuccess = createAction(
    '[Location] Add a Location Success',
    props<{addLocation: Location}>()
);

export const invokeDeleteLocation = createAction(
    '[Location] Delete a Location',
    props<{deleteLocationId: number}>()
);

export const deleteLocationSuccess = createAction(
    '[Location] Delete a Location Success',
    props<{deleteLocationId: number}>()
);

export const updateLocationsError = createAction(
    '[Location] Update Locations Error',
    props<{ error: any }>()
);
