import { createReducer, on } from "@ngrx/store";
import * as AttributeSetActions from "./attribute-set.actions";
import { AttributeSet } from "../../models/attribute-set";

export const attributeSetsFeatureKey = 'AttributeSets';

export interface AttributeSetsAppState {
    [attributeSetsFeatureKey]: AttributeSetState;
}

export interface AttributeSetState {
    attributeSets: AttributeSet[];
    selectedAttributeSet: AttributeSet|null;
    isLoading: boolean;
}

export const initialAttributeSetState: AttributeSetState =  {
    attributeSets: [],
    selectedAttributeSet: null,
    isLoading: false
}


export const AttributeSetReducer = createReducer(
    initialAttributeSetState,
    on(AttributeSetActions.loadAttributeSets, (state): AttributeSetState => {
        const attributeSets = [] as AttributeSet[];
        const isLoading = true;
        return {...state, attributeSets, isLoading};
    }),
    on(AttributeSetActions.loadAttributeSetsSuccess, (state, action): AttributeSetState => {
        const attributeSets = action.attributeSets;
        const isLoading = false;
        return {...state, attributeSets, isLoading};
    }),
    on(AttributeSetActions.updateAttributeSetSuccess, (state: AttributeSetState, { updateAttributeSet }): AttributeSetState => {
        let attributeSets = state.attributeSets.filter((_) => _.id != updateAttributeSet.id);
        attributeSets.unshift(updateAttributeSet);
        const isLoading = false;
        return {...state, attributeSets, isLoading};
    }),
    on(AttributeSetActions.addAttributeSetSuccess, (state: AttributeSetState, { addAttributeSet }): AttributeSetState => {
        let attributeSets = [...state.attributeSets];
        attributeSets.unshift(addAttributeSet);
        const isLoading = false;
        return {...state, attributeSets, isLoading};
    }),
    on(AttributeSetActions.deleteAttributeSetSuccess, (state: AttributeSetState, { deleteAttributeSetId }): AttributeSetState => {
        let attributeSets = state.attributeSets.filter((_) => _.id != deleteAttributeSetId);
        const isLoading = false;
        return {...state, attributeSets, isLoading};
    })
);
