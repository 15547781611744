import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MatrixService } from "../../services/matrix.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { MatricesAppState } from "./matrix.reducer";
import {
    loadMatrices,
    loadMatricesSuccess,
    invokeUpdateMatrix,
    updateMatrixSuccess,
    invokeAddMatrix,
    addMatrixSuccess,
    invokeDeleteMatrix,
    deleteMatrixSuccess,
    loadMatricesShort,
    loadMatricesShortSuccess,
    invokeUpdateMatrixShort,
    updateMatrixShortSuccess,
    invokeAddMatrixShort,
    invokeDeleteMatrixShort,
    deleteMatrixShortSuccess, addMatrixShortSuccess
} from "./matrix.actions";
import { selectAllMatrices, selectAllMatricesShort } from "./matrix.selectors";
import { Matrix } from "../../models/matrix";

@Injectable()
export class MatrixEffects {
    
    constructor(
        private matrixService: MatrixService,
        private actions$: Actions,
        private store: Store<MatricesAppState>,
        private appStore: Store<Appstate>
    ) {
    }
    
    matricesLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadMatrices),
            withLatestFrom(this.store.pipe(select(selectAllMatrices))),
            mergeMap(([ , matricesFromStore ]: any) => {
                if (matricesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.matrixService.getAll()
                    .pipe(map((data: Matrix[]) => loadMatricesSuccess({matrices: data})));
            })
        )
    );
    
    matricesLoadShort$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadMatricesShort),
            withLatestFrom(this.store.pipe(select(selectAllMatricesShort))),
            mergeMap(([ , matricesFromStore ]: any) => {
                if (matricesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.matrixService.getAllShort()
                    .pipe(map((data: Matrix[]) => loadMatricesShortSuccess({matrices: data})));
            })
        )
    );
    
    updateMatrix$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateMatrix),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.matrixService.update(action.updateMatrix).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateMatrixSuccess({ updateMatrix: data });
                    })
                );
            })
        );
    });
    
    updateMatrixShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeUpdateMatrixShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.matrixService.updateShort(action.updateMatrixShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return updateMatrixShortSuccess({ updateMatrixShort: data });
                    })
                );
            })
        );
    });
    
    addMatrix$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddMatrix),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.matrixService.add(action.addMatrix).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addMatrixSuccess({ addMatrix: data });
                    })
                );
            })
        );
    });
    
    addMatrixShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeAddMatrixShort),
            switchMap((action) => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: '' } })
                );
                return this.matrixService.addShort(action.addMatrixShort).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return addMatrixShortSuccess({ addMatrixShort: data });
                    })
                );
            })
        );
    });
    
    deleteMatrix$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteMatrix),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.matrixService.delete(action.deleteMatrixId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteMatrixSuccess({ deleteMatrixId: action.deleteMatrixId });
                    })
                );
            })
        );
    });
    
    deleteMatrixShort$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(invokeDeleteMatrixShort),
            switchMap((action):any => {
                this.appStore.dispatch(
                    setAPIStatus({ apiStatus: { apiResponseMessage: '', apiStatus: 'run' } })
                );
                return this.matrixService.deleteShort(action.deleteMatrixShortId).pipe(
                    map((data: any) => {
                        this.appStore.dispatch(
                            setAPIStatus({
                                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
                            })
                        );
                        return deleteMatrixShortSuccess({ deleteMatrixShortId: action.deleteMatrixShortId });
                    })
                );
            })
        );
    });
}
