import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    
    readonly INPUT_DELIMITER = '-';
    readonly OUTPUT_DELIMITER = '.';
    
    parse(value: string): NgbDateStruct | null {
        if (value && typeof value == 'string') {
            let date = value.split(this.INPUT_DELIMITER);
            return {
                year : parseInt(date[0], 10),
                month : parseInt(date[1], 10),
                day : parseInt(date[2], 10)
            };
        }
        return null;
    }
    
    format(date: NgbDateStruct | null): string {
        return date ? String(date.day).padStart(2, '0') + this.OUTPUT_DELIMITER + String(date.month).padStart(2, '0') + this.OUTPUT_DELIMITER + date.year : '';
    }
}