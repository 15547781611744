import { createReducer, on } from "@ngrx/store";
import * as CustomerActions from "./customer.actions";
import { Customer } from "../../models/customer";

export const customersFeatureKey = 'Customers';

export interface CustomersAppState {
    [customersFeatureKey]: CustomerState;
}

export interface CustomerState {
    customers: Customer[];
    selectedCustomer: Customer|null;
    isLoading: boolean;
}

export const initialCustomerState: CustomerState =  {
    customers: [],
    selectedCustomer: null,
    isLoading: false
}


export const CustomerReducer = createReducer(
    initialCustomerState,
    on(CustomerActions.loadCustomers, (state): CustomerState => {
        const customers = [] as Customer[];
        const isLoading = true;
        return {...state, customers, isLoading};
    }),
    on(CustomerActions.loadCustomersSuccess, (state, action): CustomerState => {
        const customers = action.customers;
        const isLoading = false;
        return {...state, customers, isLoading};
    }),
    on(CustomerActions.updateCustomerSuccess, (state: CustomerState, { updateCustomer }): CustomerState => {
        let customers = state.customers.filter((_) => _.id != updateCustomer.id);
        customers.unshift(updateCustomer);
        const isLoading = false;
        return {...state, customers, isLoading};
    }),
    on(CustomerActions.addCustomerSuccess, (state: CustomerState, { addCustomer }): CustomerState => {
        let customers = [...state.customers];
        customers.unshift(addCustomer);
        const isLoading = false;
        return {...state, customers, isLoading};
    }),
    on(CustomerActions.deleteCustomerSuccess, (state: CustomerState, { deleteCustomerId }): CustomerState => {
        let customers = state.customers.filter((_) => _.id != deleteCustomerId);
        // customers.shift(deleteCustomer);
        const isLoading = false;
        return {...state, customers, isLoading};
    })
);
