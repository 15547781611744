<div class="bg-login">
    <!-- wrapper -->
    <div class="wrapper">
        <div class="authentication-forgot d-flex align-items-center justify-content-center">
            <div class="col-md-3 mb-3">
            <div class="card forgot-box">
                <div class="card-body">

                    <form class="row g-3" [formGroup]="form" *ngIf="!requestSent" novalidate>
                        <div class="p-4 rounded  border">
                            <div class="mb-4 text-center bg-exctra py-1">
                                <img src="assets/images/syme-logo.png" width="180" alt=""/>
                            </div>
                            <h4 class="mt-5 font-weight-bold">{{'translations.LOGIN_FORM_FORGOT_PASSWORD'|translate}}</h4>
                            <p class="text-center">{{'translations.FORGOT_PASSWORD_FORM_HEADLINE'|translate}}</p>
                            <div class="my-4">
                                <label class="form-label">{{'translations.LOGIN_FORM_USERNAME'|translate}}</label>
                                <input type="text" class="form-control form-control-lg" name="username" formControlName="username" [placeholder]="'translations.LOGIN_FORM_USERNAME'|translate" />
                                <div class="field-message" *ngIf="(formControl['username'].touched || submitted) && formControl['username'].errors?.['required']">
                                    {{'translations.FORM_USERNAME_REQUIRED'|translate}}
                                </div>
                            </div>
                            <div class="my-4">
                                <label class="form-label">{{'translations.FORGOT_PASSWORD_FORM_EMAIL'|translate}}</label>
                                <input type="email" class="form-control form-control-lg" name="email" formControlName="email" [placeholder]="'translations.FORGOT_PASSWORD_FORM_EMAIL_PLACEHOLDER'|translate" />
                                <div class="field-message" *ngIf="(formControl['email'].touched || submitted) && (formControl['email'].errors?.['required'] || formControl['email'].errors?.['email'])">
                                    {{'translations.FORM_INVALID_EMAIL'|translate}}
                                </div>
                            </div>
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-exctra" (click)="onSend()">{{'translations.ACTION_SEND'|translate}}</button> <a href="javascript:;" (click)="onSignIn()"><i class="material-icons inline mr-2">arrow_back</i>{{'translations.FORGOT_PASSWORD_FORM_BACK_TO_LOGIN'|translate}}</a>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="requestSent">
                        <h4 class="card-title mb-3">{{'translations.FORGOT_PASSWORD_FORM_REQUEST_SENT'|translate}}</h4>
                        <div *ngIf="!responseError">{{'translations.' + responseMessage|translate}}</div>
                        <div *ngIf="responseError" class="alert alert-danger border-0 bg-danger alert-dismissible fade show py-2">
                            <div class="d-flex align-items-center">
                                <div class="font-35 text-white">
                                    <i class="bx bxs-message-square-x"></i>
                                </div>
                                <div class="ms-3">
                                    <h6 class="mb-2 text-white">{{'translations.HINT_WARNING'|translate}}</h6>
                                    <div class="text-white">{{'translations.' + responseMessage|translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4"><a href="JavaScript:;" (click)="onBack()"><i class="material-icons">arrow_right_alt</i>{{'translations.BACK'|translate}}</a></div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- end wrapper -->
</div>