import { createReducer, on } from "@ngrx/store";
import * as UserActions from "./user.actions";
import { User } from "../../models/user";

export const usersFeatureKey = 'Users';

export interface UsersAppState {
    [usersFeatureKey]: UserState;
}

export interface UserState {
    users: User[];
    selectedUser: User|null;
    isLoading: boolean;
}

export const initialUserState: UserState =  {
    users: [],
    selectedUser: null,
    isLoading: false
}


export const UserReducer = createReducer(
    initialUserState,
    on(UserActions.loadUsers, (state): UserState => {
        const users = [] as User[];
        const isLoading = true;
        return {...state, users, isLoading};
    }),
    on(UserActions.loadUsersSuccess, (state, action): UserState => {
        const users = action.users;
        const isLoading = false;
        return {...state, users, isLoading};
    }),
    on(UserActions.updateUserSuccess, (state: UserState, { updateUser }): UserState => {
        let users = state.users.filter((_) => _.id != updateUser.id);
        users.unshift(updateUser);
        const isLoading = false;
        return {...state, users, isLoading};
    }),
    on(UserActions.addUserSuccess, (state: UserState, { addUser }): UserState => {
        let users = [...state.users];
        users.unshift(addUser);
        const isLoading = false;
        return {...state, users, isLoading};
    }),
    on(UserActions.deleteUserSuccess, (state: UserState, { deleteUserId }): UserState => {
        let users = state.users.filter((_) => _.id != deleteUserId);
        const isLoading = false;
        return {...state, users, isLoading};
    })
);
