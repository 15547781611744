import { createReducer, on } from "@ngrx/store";
import * as CountryActions from "./country.actions";
import { Country } from "../../models/country";

export const countriesFeatureKey = 'Countries';

export interface CountriesAppState {
    [countriesFeatureKey]: CountryState;
}

export interface CountryState {
    countries: Country[];
    selectedCountry: Country|null;
    isLoading: boolean;
}

export const initialCountryState: CountryState =  {
    countries: [],
    selectedCountry: null,
    isLoading: false
}


export const CountryReducer = createReducer(
    initialCountryState,
    on(CountryActions.loadCountries, (state): CountryState => {
        const countries = [] as Country[];
        const isLoading = true;
        return {...state, countries, isLoading};
    }),
    on(CountryActions.loadCountriesSuccess, (state, action): CountryState => {
        const countries = action.countries;
        const isLoading = false;
        return {...state, countries, isLoading};
    })
);
