import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LanguageService } from "../../services/language.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { LanguagesAppState } from "./language.reducer";
import {
    loadLanguages,
    loadLanguagesSuccess,
} from "./language.actions";
import { selectAllLanguages } from "./language.selectors";
import { Language } from "../../models/language";

@Injectable()
export class LanguageEffects {

    constructor(
        private languageService: LanguageService,
        private actions$: Actions,
        private store: Store<LanguagesAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    languagesLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadLanguages),
            withLatestFrom(this.store.pipe(select(selectAllLanguages))),
            mergeMap(([ , languagesFromStore ]: any) => {
                if (languagesFromStore.length > 0) {
                    return EMPTY;
                }
                return this.languageService.getAll()
                    .pipe(map((data: Language[]) => loadLanguagesSuccess({languages: data})));
            })
        )
    );
}
