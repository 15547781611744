import { createAction, props } from "@ngrx/store";
import { Customer } from "../../models/customer";

export const loadCustomers = createAction(
    '[Customer] Load all Customers'
);

export const loadCustomersSuccess = createAction(
    '[Customer] Load all Customers Success',
    props<{ customers: Customer[] }>()
);

export const loadCustomersError = createAction(
    '[Customer] Load Customers Error',
    props<{ error: any }>()
);

export const invokeUpdateCustomer = createAction(
    '[Customer] Update a Customer',
    props<{updateCustomer: Customer}>()
);

export const updateCustomerSuccess = createAction(
    '[Customer] Update a Customer Success',
    props<{updateCustomer: Customer}>()
);

export const invokeAddCustomer = createAction(
    '[Customer] Add a Customer',
    props<{addCustomer: Customer}>()
);

export const addCustomerSuccess = createAction(
    '[Customer] Add a Customer Success',
    props<{addCustomer: Customer}>()
);

export const invokeDeleteCustomer = createAction(
    '[Customer] Delete a Customer',
    props<{deleteCustomerId: number}>()
);

export const deleteCustomerSuccess = createAction(
    '[Customer] Delete a Customer Success',
    props<{deleteCustomerId: number}>()
);

export const updateCustomersError = createAction(
    '[Customer] Update Customers Error',
    props<{ error: any }>()
);
