import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./modules/auth/auth.interceptor";

import { SharedModule } from "./shared/shared.module";

import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar-angul'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-angul';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar-angul';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LegacyModule } from "./modules/legacy/legacy.module";
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from "./modules/auth/auth.module";
import { JwtModule } from "@auth0/angular-jwt";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from "../environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducer } from "./stores/app/app.reducer";
import * as fromUser from "./stores/users/user.reducer";
import * as fromLanguage from "./stores/languages/language.reducer";
import * as fromCountry from "./stores/countries/country.reducer";
import * as fromCustomer from "./stores/customers/customer.reducer";
import * as fromProject from "./stores/projects/project.reducer";
import * as fromMatrix from "./stores/matrices/matrix.reducer";
import * as fromAttribute from "./stores/attributes/attribute.reducer";
import * as fromAttributeSet from "./stores/attribute-sets/attribute-set.reducer";
import * as fromEmployee from "./stores/employees/employee.reducer";
import { UserEffects } from "./stores/users/user.effects";
import { LanguageEffects } from "./stores/languages/language.effects";
import { CountryEffects } from "./stores/countries/country.effects";
import { CustomerEffects } from "./stores/customers/customer.effects";
import { ProjectEffects } from "./stores/projects/project.effects";
import { MatrixEffects } from "./stores/matrices/matrix.effects";
import { AttributeEffects } from "./stores/attributes/attribute.effects";
import { AttributeSetEffects } from "./stores/attribute-sets/attribute-set.effects";
import { EmployeeEffects } from "./stores/employees/employee.effects";
import { config } from "./app.config";
import { RestangularModule } from "@ausy_gl/ngx-restangular";
import { registerLocaleData } from "@angular/common";
import * as de from '@angular/common/locales/de';
import { CustomDateParserFormatter } from "./services/customDateParser.formatter";
import { SortablejsModule } from "@mksasi/ngx-sortablejs";
import { NgIdleModule } from "@ng-idle/core";
import * as fromLocation from "./stores/locations/location.reducer";
import * as fromRegion from "./stores/regions/region.reducer";
import { LocationEffects } from "./stores/locations/location.effects";
import { RegionEffects } from "./stores/regions/region.effects";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AuthService } from "./modules/auth/auth.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function tokenGetter() {
    const user =  JSON.parse(localStorage.getItem('user'));
    if (user) {
        return  user.token;
    }
    return;
}

export function RestangularConfigFactory (RestangularProvider: any, authService: AuthService) {
    RestangularProvider.setBaseUrl(config.restServers.basic.url + '/');
    RestangularProvider.setDefaultHeaders({'Content-Type': 'application/ld+json'});
    
    // by each request to the server receive a token and update headers with it
    RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
        let bearerToken = tokenGetter();
        return {
            headers: Object.assign({}, headers, {Authorization: `Bearer ${bearerToken}`})
        };
    });
    RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
        if (data) {
            if (response && response.hasOwnProperty('url') && response.url.indexOf('/dt') != -1) {
                return data;
            }
            return data.hasOwnProperty('hydra:member') ? data['hydra:member'] : data;
        }
    });
    
    RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
        if (response.status === 401) {
            authService.logout();
            return false;
        }
        return true;
    });
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/messages.');
}

@NgModule({
    declarations: [
        AppComponent,
        ContentLayoutComponent,
        FullLayoutComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        LegacyModule,
        NgbModule,
        SharedModule,
        PerfectScrollbarModule,
        RestangularModule.forRoot([AuthService], RestangularConfigFactory),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [ HttpClient ]
            }
        }),
        AuthModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: [ "se3.ddev.site" ],
                disallowedRoutes: [ "http://example.com/examplebadroute/" ],
            },
        }),
        SortablejsModule.forRoot({animation: 150}),
        StoreModule.forRoot({appState: appReducer}, {}),
        StoreModule.forFeature(
            fromUser.usersFeatureKey,
            fromUser.UserReducer
        ),
        StoreModule.forFeature(
            fromLanguage.languagesFeatureKey,
            fromLanguage.LanguageReducer
        ),
        StoreModule.forFeature(
            fromCountry.countriesFeatureKey,
            fromCountry.CountryReducer
        ),
        StoreModule.forFeature(
            fromCustomer.customersFeatureKey,
            fromCustomer.CustomerReducer
        ),
        StoreModule.forFeature(
            fromProject.projectsFeatureKey,
            fromProject.ProjectReducer
        ),
        StoreModule.forFeature(
            fromProject.projectsShortFeatureKey,
            fromProject.ProjectShortReducer
        ),
        StoreModule.forFeature(
            fromMatrix.matricesFeatureKey,
            fromMatrix.MatrixReducer
        ),
        StoreModule.forFeature(
            fromMatrix.matricesShortFeatureKey,
            fromMatrix.MatrixShortReducer
        ),
        StoreModule.forFeature(
            fromAttribute.attributesFeatureKey,
            fromAttribute.AttributeReducer
        ),
        StoreModule.forFeature(
            fromAttributeSet.attributeSetsFeatureKey,
            fromAttributeSet.AttributeSetReducer
        ),
        StoreModule.forFeature(
            fromEmployee.employeesFeatureKey,
            fromEmployee.EmployeeReducer
        ),
        StoreModule.forFeature(
            fromEmployee.employeesShortFeatureKey,
            fromEmployee.EmployeeShortReducer
        ),
        StoreModule.forFeature(
            fromLocation.locationsFeatureKey,
            fromLocation.LocationReducer),
        StoreModule.forFeature(
            fromRegion.regionsFeatureKey,
            fromRegion.RegionReducer),
        EffectsModule.forRoot([]),
        EffectsModule.forFeature([
            UserEffects,
            LanguageEffects,
            CountryEffects,
            CustomerEffects,
            ProjectEffects,
            MatrixEffects,
            AttributeEffects,
            AttributeSetEffects,
            EmployeeEffects,
            LocationEffects,
            RegionEffects
        ]),
        NgIdleModule.forRoot(),
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        !environment.production ? StoreDevtoolsModule.instrument() : []
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        {provide: LOCALE_ID, useValue: 'de'}
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
    
    constructor() {
        registerLocaleData(de.default); //Then register the language
        console.log(environment.name, environment.apiHost);
    }
}
