import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAttributeSet from "./attribute-set.reducer";
import { AttributeSetState } from "./attribute-set.reducer";

export const selectAttributeSetState = createFeatureSelector<fromAttributeSet.AttributeSetState>(
    fromAttributeSet.attributeSetsFeatureKey
);

export const selectAllAttributeSets = createSelector(
    selectAttributeSetState,
    ls => ls.attributeSets
)

export const selectAttributeSets = createFeatureSelector<AttributeSetState>('AttributeSets');

export const selectAttributeSetById = (attributeSetId: number) => createSelector(
    selectAttributeSets, (attributeSetState: AttributeSetState) => {
        let attributeSetById = attributeSetState.attributeSets.filter((_) => _.id == attributeSetId);
        if (attributeSetById.length == 0) {
            return null;
        }
        return attributeSetById[0];
    });
