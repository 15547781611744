import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Restangular } from "@ausy_gl/ngx-restangular";
import { Customer } from "../models/customer";

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    
    resourceType: string = 'customers';
    resource$ = null;
    
    constructor(
        private restangular: Restangular
    ) {
        this.resource$ = this.restangular.all(this.resourceType);
    }
    
    getAll(): Observable<any> {
        return this.resource$.getList();
    }
    
    getProjects(id: number, onlyActive: boolean = false) {
        let payload = {};
        if (onlyActive) {
            payload = {active: true}
        }
        return this.restangular.one(this.resourceType, id).customGETLIST('projects', payload);
    }
    
    getLocations(id: number) {
        return this.restangular.one(this.resourceType, id).customGETLIST('locations');
    }
    
    getRegions(id: number) {
        return this.restangular.one(this.resourceType, id).customGETLIST('regions');
    }
    
    add(payload: Customer) {
        return this.resource$.post(payload);
    }
    
    update(payload: Customer) {
        return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
    }
    
    delete(id: number): Observable<any> {
        return this.restangular.one(this.resourceType, id).remove(id);
    }
}
