import { createAction, props } from "@ngrx/store";
import { User } from "../../models/user";

export const loadUsers = createAction(
    '[User] Load all Users'
);

export const loadUsersSuccess = createAction(
    '[User] Load all Users Success',
    props<{ users: User[] }>()
);

export const loadUsersError = createAction(
    '[User] Load Users Error',
    props<{ error: any }>()
);

export const invokeUpdateUser = createAction(
    '[User] Update a User',
    props<{updateUser: User}>()
);

export const updateUserSuccess = createAction(
    '[User] Update a User Success',
    props<{updateUser: User}>()
);

export const invokeAddUser = createAction(
    '[User] Add a User',
    props<{addUser: User}>()
);

export const addUserSuccess = createAction(
    '[User] Add a User Success',
    props<{addUser: User}>()
);

export const invokeDeleteUser = createAction(
    '[User] Delete a User',
    props<{deleteUserId: number}>()
);

export const deleteUserSuccess = createAction(
    '[User] Delete a User Success',
    props<{deleteUserId: number}>()
);

export const updateUsersError = createAction(
    '[User] Update Users Error',
    props<{ error: any }>()
);
