import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { map, Observable } from "rxjs";
import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  resourceType: string = 'users';
  resource$ = null;

  constructor(
      private restangular: Restangular
  ) {
    this.resource$ = this.restangular.all(this.resourceType);
  }
  
  getAll(): Observable<any> {
    return this.resource$.getList();
  }
  
  getById(id: number): Observable<any> {
    return this.resource$.get(id);
  }
  
  requestPassword(payload: any): Observable<any> {
    return this.restangular.all('').customGET('forgot-password', payload)
        .pipe(
            map((response:any) => response.message)
        );
  }
  
  resetPassword(payload: any): Observable<any> {
    return this.restangular.all('').customGET('reset-password', payload)
        .pipe(
            map((response:any) => response.message)
        );
  }
  
  renewPassword(payload: any): Observable<any> {
    return this.restangular.all('').customGET('renew-password', payload)
        .pipe(
            map((response:any) => response.message)
        );
  }
  
  add(payload: User) {
    return this.resource$.post(payload);
  }
  
  update(payload: User) {
    return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
  }
  
  delete(id: number): Observable<any> {
    return this.restangular.one(this.resourceType, id).remove(id);
  }
  
  getEvaluations(id: number) {
    return this.restangular.one(this.resourceType, id).customGETLIST('evaluations');
  }
}
