import { createAction, props } from "@ngrx/store";
import { Country } from "../../models/country";

export const loadCountries = createAction(
    '[Country] Load all Countries'
);

export const loadCountriesSuccess = createAction(
    '[Country] Load all Countries Success',
    props<{ countries: Country[] }>()
);

export const loadCountriesError = createAction(
    '[Country] Load Countries Error',
    props<{ error: any }>()
);
