import { Injectable } from '@angular/core';
import { Restangular } from "@ausy_gl/ngx-restangular";
import { AttributeSet } from "../models/attribute-set";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AttributeSetService {
  
  resourceType: string = 'attribute_sets';
  resource$ = null;
  
  constructor(
      private restangular: Restangular
  ) {
    this.resource$ = this.restangular.all(this.resourceType);
  }
  
  getAll(): Observable<any> {
    return  this.resource$.getList();
  }
  
  update(payload: AttributeSet) {
    return this.restangular.one(this.resourceType, payload.id).customPUT(payload);
  }
  
  add(payload: AttributeSet) {
    return this.resource$.post(payload);
  }
  
  delete(id: number) {
    return this.restangular.one(this.resourceType, id).remove(id);
  }
}
