import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRegion from "./region.reducer";
import { RegionState } from "./region.reducer";

export const selectRegionState = createFeatureSelector<fromRegion.RegionState>(
    fromRegion.regionsFeatureKey
);

export const selectAllRegions = createSelector(
    selectRegionState,
    ls => ls.regions
)

export const selectRegions = createFeatureSelector<RegionState>('Regions');

export const selectRegionById = (regionId: number) => createSelector(
    selectRegions, (regionState: RegionState) => {
        let regionById = regionState.regions.filter((_) => _.id == regionId);
        if (regionById.length == 0) {
            return null;
        }
        return regionById[0];
    });
