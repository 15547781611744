import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CountryService } from "../../services/country.service";
import { select, Store } from "@ngrx/store";
import { EMPTY, mergeMap, withLatestFrom, switchMap, tap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setAPIStatus} from "../app/app.action";
import { Appstate } from "../app/appstate";
import { CountriesAppState } from "./country.reducer";
import {
    loadCountries,
    loadCountriesSuccess,
} from "./country.actions";
import { selectAllCountries } from "./country.selectors";
import { Country } from "../../models/country";

@Injectable()
export class CountryEffects {

    constructor(
        private countryService: CountryService,
        private actions$: Actions,
        private store: Store<CountriesAppState>,
        private appStore: Store<Appstate>
    ) {
    }

    countrysLoad$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(loadCountries),
            withLatestFrom(this.store.pipe(select(selectAllCountries))),
            mergeMap(([ , countrysFromStore ]: any) => {
                if (countrysFromStore.length > 0) {
                    return EMPTY;
                }
                return this.countryService.getAll()
                    .pipe(map((data: Country[]) => loadCountriesSuccess({countries: data})));
            })
        )
    );
}
