import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromCustomer from "./customer.reducer";
import { CustomerState } from "./customer.reducer";

export const selectCustomerState = createFeatureSelector<fromCustomer.CustomerState>(
    fromCustomer.customersFeatureKey
);

export const selectAllCustomers = createSelector(
    selectCustomerState,
    cs => cs.customers
)

export const selectCustomers = createFeatureSelector<CustomerState>('Customers');

export const selectCustomerById = (customerId: number) => createSelector(
    selectCustomers, (customerState: CustomerState) => {
        let customerById = customerState.customers.filter((_) => _.id == customerId);
        if (customerById.length == 0) {
            return null;
        }
        return customerById[0];
    });
