import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromEmployee from "./employee.reducer";
import { EmployeeShortState, EmployeeState } from "./employee.reducer";

export const selectEmployeeState = createFeatureSelector<fromEmployee.EmployeeState>(
    fromEmployee.employeesFeatureKey
);

export const selectEmployeeShortState = createFeatureSelector<fromEmployee.EmployeeShortState>(
    fromEmployee.employeesShortFeatureKey
);

export const selectAllEmployees = createSelector(
    selectEmployeeState,
    ps => ps.employees
)

export const selectAllEmployeesShort = createSelector(
    selectEmployeeShortState,
    ps => ps.employees
)

export const selectLastAddedEmployee = createSelector(
    selectAllEmployeesShort,
    employees => employees.length > 0 ? employees[0] : null
)

export const selectEmployees = createFeatureSelector<EmployeeState>('Employees');

export const selectEmployeesShort = createFeatureSelector<EmployeeShortState>('EmployeesShort');

export const selectEmployeeShortById = (employeeId: number) => createSelector(
    selectEmployeesShort, (employeeShortState: EmployeeShortState) => {
        let employeeById = employeeShortState.employees.filter((_) => _.id == employeeId);
        if (employeeById.length == 0) {
            return null;
        }
        return employeeById[0];
    });

export const selectEmployeeById = (employeeId: number) => createSelector(
    selectEmployees, (employeeState: EmployeeState) => {
        let employeeById = employeeState.employees.filter((_) => _.id == employeeId);
        if (employeeById.length == 0) {
            return null;
        }
        return employeeById[0];
    });

export const selectEmployeesShortByLocationId = (locationId: number) => createSelector(
    selectEmployeesShort, (employeeShortState: EmployeeShortState) => {
        let employeeByLocationId = employeeShortState.employees.filter((_) => _.hasOwnProperty('location') && _.location.id == locationId);
        if (employeeByLocationId.length == 0) {
            return null;
        }
        return employeeByLocationId;
    });
