import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromLanguage from "./language.reducer";
import { LanguageState } from "./language.reducer";

export const selectLanguageState = createFeatureSelector<fromLanguage.LanguageState>(
    fromLanguage.languagesFeatureKey
);

export const selectAllLanguages = createSelector(
    selectLanguageState,
    ls => ls.languages
)

export const selectLanguages = createFeatureSelector<LanguageState>('Languages');
//
// export const selectUserById = (userId: number) => createSelector(
//     selectUsers, (userState: UserState) => {
//         let userById = userState.users.filter((_) => _.id == userId);
//         if (userById.length == 0) {
//             return null;
//         }
//         return userById[0];
//     });
