<div class="bg-login">
    <!-- wrapper -->
    <div class="wrapper">
        <div class="authentication-forgot d-flex align-items-center justify-content-center">
            <div class="col-md-3 mb-3">
            <div class="card forgot-box">
                <div class="card-body">

                    <form class="row g-3" [formGroup]="form" *ngIf="!requestSent" novalidate>
                        <input type="hidden" formControlName="resetToken" />
                        <div class="p-4 rounded  border">
                            <div class="mb-4 text-center bg-exctra py-1">
                                <img src="assets/images/syme-logo.png" width="180" alt=""/>
                            </div>
                            <h4 class="mt-5 font-weight-bold">{{'translations.RESET_PASSWORD_FORM_HEADLINE'|translate}}</h4>
                            <p class="text-muted">{{'translations.RESET_PASSWORD_FORM_HINT'|translate}}</p>
                            <div class="my-4">
                                <label class="form-label">{{'translations.RESET_PASSWORD_FORM_PASSWORD'|translate}}</label>
                                <input type="password" class="form-control form-control-lg" formControlName="password" required />
                                <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['required']">
                                    {{'translations.PLS_INSERT_PASSWORD'|translate}}
                                </div>
                                <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['minlength']">
                                    {{'translations.PASSWORD_MINLENGTH_ERROR'|translate: {passwordMinLength: passwordMinLength} }}
                                </div>
                                <div class="field-message" *ngIf="(formControl['password'].touched || submitted) && formControl['password'].errors?.['pattern']">
                                    {{'translations.PASSWORD_PATTERN_ERROR'|translate}}
                                </div>
                            </div>
                            <div class="my-4 col-12">
                                <label class="form-label">{{'translations.RESET_PASSWORD_FORM_PASSWORD_REPEAT'|translate}}</label>
                                <input type="password" class="form-control form-control-lg" formControlName="password_repeat" />
                                <div class="field-message" *ngIf="(formControl['password_repeat'].touched || submitted) && formControl['password_repeat'].errors?.['required']">
                                    {{'translations.PLS_REPEAT_PASSWORD'|translate}}
                                </div>

                                <div class="field-message"  *ngIf="(formControl['password_repeat'].touched || submitted) && form.errors?.['notTheSame']">
                                    {{'translations.PW_AND_REPEAT_NOT_MATCHING_ERROR'|translate}}
                                </div>
                            </div>
                            <div class="d-grid gap-2">
                                <button type="submit" class="btn btn-exctra" (click)="onSend()">{{'translations.ACTION_SEND'|translate}}</button>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="requestSent">
                        <h4 class="card-title mb-3">{{'translations.FORGOT_PASSWORD_FORM_REQUEST_SENT'|translate}}</h4>
                        <div *ngIf="!responseError">{{'translations.' + responseMessage|translate}}</div>
                        <div *ngIf="responseError" class="alert alert-danger border-0 bg-danger alert-dismissible fade show py-2">
                            <div class="d-flex align-items-center">
                                <div class="font-35 text-white">
                                    <i class="bx bxs-message-square-x"></i>
                                </div>
                                <div class="ms-3">
                                    <h6 class="mb-2 text-white">{{'translations.HINT_WARNING'|translate}}</h6>
                                    <div class="text-white">{{'translations.' + responseMessage|translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4"><a href="JavaScript:;" (click)="onBack()"><i class='bx bx-arrow-back me-1'></i>{{'translations.FORGOT_PASSWORD_FORM_BACK_TO_LOGIN'|translate}}</a></div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- end wrapper -->
</div>